<template>
    <div :style="{'height': __layoutCenterHeightCalculate.px}">
        <h2 class="my-2 d-flex justify-content-center">Vue leaflet</h2>
        <!-- settings -->
        <b-button-toolbar class="m-2">
            <b-button class="mr-1" @click="layers()">Layers</b-button>
            <b-button class="mr-1" @click="circleOnLayers()">Circle</b-button>
            <b-button class="mr-1" @click="addMarkers()">AddMarkers</b-button>
            <b-button class="mr-1" @click="routingMachine()">Routing deprecated</b-button>
            <b-button class="mr-1" @click="routing()">Routing</b-button>
            <b-button class="mr-1" @click="clearRoute()">Clear route</b-button>
            <b-button class="mr-1" @click="getView()">Get view</b-button>
        </b-button-toolbar>
        <!--        <b-button-toolbar class="mx-2">-->
        <!--            <b-form-checkbox-->
        <!--                id="active"-->
        <!--                v-model="scrollWheelZoom"-->
        <!--                class="float-right"-->
        <!--                name="active"-->
        <!--                switch-->
        <!--            >-->
        <!--                scrollWheelZoom-->
        <!--            </b-form-checkbox>-->
        <!--        </b-button-toolbar>-->

        <!-- lmap -->
        <!--            :max-bounds="rectangle.bounds"-->
        <l-map
            ref="dlMap"
            :min-zoom="minZoom"
            :max-zoom="maxZoom"
            :zoom.sync="zoom"
            :center.sync="center"
            :options="mapOptions"
            style="height: 600px; width: 100%"
            @update:center="centerUpdate"
            @update:zoom="zoomUpdate"
        >
            @mouseup.left="mouseEvent"
            <!--            @click="addPoint"-->
            <!-- control layers -->
            <l-control-layers
                :position="layersPosition"
                :collapsed="true"
                :sort-layers="false"
            />
            <!-- tile-layer -->
            <l-tile-layer
                v-for="tileProvider in getTileProviders"
                :key="tileProvider.name"
                :name="tileProvider.name"
                :visible="tileProvider.visible"
                :url="tileProvider.url"
                :subdomains="tileProvider.subdomains"
                :attribution="tileProvider.attribution"
                :token="token"
                layer-type="base"
                :opacity="1"
            />
            <!-- control-zoom-position -->
            <l-control-zoom :position="zoomPosition"/>
            <!-- control attribution -->
            <l-control-attribution
                :position="attributionPosition"
                :prefix="attributionPrefix"
            />
            <!-- control-scale -->
            <l-control-scale :imperial="imperial"/>
            <!-- bounds rectangle -->
            <l-rectangle
                layer-type="overlay"
                name="Bounds"
                :visible="true"
                :bounds="rectangle.bounds"
                :l-style="rectangle.style"
            ></l-rectangle>
            <!-- las Prlekija - polygon -->
            <l-polygon
                layer-type="overlay"
                name="LAS Prlekija: polygon"
                :visible="true"
                v-for="item in polyLasPrlekija"
                :key="item.id"
                :lat-lngs="item.points"
                :stroke="true"
                :options="{color: 'blue'}"
                @click.right="polygonClick()"
            />
            <!-- las Prlekija - polyLine -->
            <l-polyline
                layer-type="overlay"
                name="LAS Prlekija: polyLine"
                :visible="false"
                v-for="item in polyLasPrlekija"
                :lat-lngs="item.points"
                @click="alert(item)"
                :options="{color: 'red'}"
            />
            <!-- layerGroup: popup -->
            <l-layer-group ref="features">
                <l-popup
                    :options="popupOptions"
                >
                    <popup-test
                        :test-prop="caller"
                    ></popup-test>
                </l-popup>
            </l-layer-group>
        </l-map>
        <!--  -->
        <div class="mt-1">
            Zoom: {{zoom}} Center: {{center}} caller: {{this.caller}}
        </div>
        <div class="mt-1">
            <b>Points:</b> {{points}}
        </div>
        <!-- popup -->
        <div ref="popupLayer" v-show="false">
            <b-card
                title="Card Title"
                img-src="https://picsum.photos/600/300/?image=25"
                img-alt="Image"
                img-top
                tag="article"
                style="max-width: 20rem;"
                class="mb-2"
            >
                <b-card-text>
                    <p>caller: {{caller}}</p>
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                </b-card-text>

                <b-button href="#" variant="primary">Go somewhere</b-button>
            </b-card>&ndash;&gt;
            <popup-test
                :test-prop="caller"
            ></popup-test>
        </div>
    </div>
</template>

<script>
import L, {latLng} from "leaflet";
import {GestureHandling} from "leaflet-gesture-handling";
import PopupTest from "@/components/developer/leaflet/PopupTest";

// @group DEVELOPER
export default {
    name: "VueLeaflet",
    components: {PopupTest},
    props: {
        //Default map zoom
        mapZoom: {
            type: Number,
            default: 11,
        },
        //Default map center latitude
        mapCenterLat: {
            type: Number,
            default: 46.596147,
        },
        //Default map center longitude
        mapCenterLng: {
            type: Number,
            default: 16.033516,
        },
        //Default zoom snap (step)
        mapZoomSnap: {
            type: Number,
            default: 0.5,
        },
        //The map be draggable with mouse/touch
    },
    data() {
        return {
            caller: 'null',
            routingControl: null,
            // <-- lmap &ndash;&gt;
            mapOptions: {
                zoomControl: false,
                attributionControl: false,
                dragging: true,
                zoomSnap: true, //0.5 ???
                // <-- context menu &ndash;&gt;
                gestureHandling: true,
                gestureHandlingOptions: {
                    duration: 5000, //5 secs
                    // text: {
                    //     touch: "Hey bro, use two fingers to move the map",
                    //     scroll: "Hey bro, use ctrl + scroll to zoom the map",
                    //     scrollMac: "Hey bro, use \u2318 + scroll to zoom the map"
                    // }
                },
                contextmenu: true,
                // contextmenuWidth: 140,
                contextmenuItems: [
                    {
                        text: 'Coordinates',
                    },
                    {
                        separator: true,
                    },
                    {
                        text: 'Dodaj točko',
                        callback: this.addPoint
                    },
                    {
                        text: 'Izbriši zadnjo točko',
                        // icon: 'images/zoom-out.png',
                        callback: this.undoPoint
                    },
                    '-', // separator
                    {
                        text: 'Show coordinates',
                        callback: this.showCoordinates
                    },
                    {
                        text: 'Center map here',
                        disabled: true,
                        callback: this.centerMap,
                    },
                    '-', // separator
                    // {
                    //     separator: true,
                    // },
                    {
                        text: 'Zoom in',
                        // icon: 'images/zoom-in.png',  16x16
                        callback: this.zoomIn
                    },
                    {
                        text: 'Zoom out',
                        // icon: 'images/zoom-out.png',
                        callback: this.zoomOut
                    },
                ]
            },
            center: latLng(this.mapCenterLat, this.mapCenterLng),
            zoom: this.mapZoom,
            minZoom: 1,
            maxZoom: 18,
            token: null,

            // <-- popup -->;
            popupOptions: {
                closeButton: false,
                minWidth: 250,
                autoPan: true,
                minHeight: 400,
            },

            // <-- layers &ndash;&gt;
            layersPosition: 'topright',
            zoomPosition: 'topleft',    // 'topleft', 'topright', 'bottomleft', 'bottomright'
            attributionPosition: 'bottomright',
            attributionPrefix: 'Leaflet',
            imperial: false,

            polyLasPrlekija: [
                {
                    id: 'lasPrlekija1',
                    points: [
                        {"lat": 46.70748394048617, "lng": 15.786452293395998}, {
                            "lat": 46.70851759087577,
                            "lng": 15.78837811946869
                        }, {"lat": 46.70920177562005, "lng": 15.78937590122223}, {
                            "lat": 46.71040091720808,
                            "lng": 15.791119337081911
                        }, {"lat": 46.71291682094444, "lng": 15.795421600341799}, {
                            "lat": 46.714233577606045,
                            "lng": 15.798216462135317
                        }, {"lat": 46.71514940337914, "lng": 15.800732374191286}, {
                            "lat": 46.71605417984433,
                            "lng": 15.803849101066591
                        }, {"lat": 46.71711708891416, "lng": 15.808210372924806}, {
                            "lat": 46.71867279696659,
                            "lng": 15.815141201019289
                        }, {"lat": 46.71943776225365, "lng": 15.818547606468202}, {
                            "lat": 46.72075435980284,
                            "lng": 15.82558035850525
                        }, {"lat": 46.72148619762045, "lng": 15.829437375068666}, {
                            "lat": 46.72198266486037,
                            "lng": 15.832650661468508
                        }, {"lat": 46.722284220509174, "lng": 15.835987329483034}, {
                            "lat": 46.72239086783519,
                            "lng": 15.839651226997377
                        }, {"lat": 46.722354092919005, "lng": 15.848293304443361}, {
                            "lat": 46.722332027957236,
                            "lng": 15.856211185455324
                        }, {"lat": 46.72215550793844, "lng": 15.861098170280458}, {
                            "lat": 46.7218944043516,
                            "lng": 15.86430072784424
                        }, {"lat": 46.72119199265585, "lng": 15.869616866111757}, {
                            "lat": 46.72045647322541,
                            "lng": 15.87376892566681
                        }, {"lat": 46.718834617450966, "lng": 15.880286693573}, {
                            "lat": 46.717631985434025,
                            "lng": 15.883880853652956
                        }, {"lat": 46.71471539952857, "lng": 15.890795588493349}, {
                            "lat": 46.71398346987382,
                            "lng": 15.892828702926638
                        }, {"lat": 46.71338762031228, "lng": 15.8955055475235}, {
                            "lat": 46.71237245405549,
                            "lng": 15.902683138847353
                        }, {"lat": 46.711588997476284, "lng": 15.906550884246828}, {
                            "lat": 46.710066190181394,
                            "lng": 15.911695361137392
                        }, {"lat": 46.70833734721491, "lng": 15.916056632995607}, {
                            "lat": 46.7070940174039,
                            "lng": 15.918540358543398
                        }, {"lat": 46.7055379755474, "lng": 15.921136736869814}, {
                            "lat": 46.70487213778786,
                            "lng": 15.922118425369264
                        }, {"lat": 46.70435711952334, "lng": 15.922880172729494}, {
                            "lat": 46.70359929800029,
                            "lng": 15.9239798784256
                        }, {"lat": 46.70322774084284, "lng": 15.924516320228578}, {
                            "lat": 46.702506691933955,
                            "lng": 15.925589203834535
                        }, {"lat": 46.69893200034408, "lng": 15.930755138397219}, {
                            "lat": 46.698242741594534,
                            "lng": 15.931667089462282
                        }, {"lat": 46.69678578405358, "lng": 15.93372702598572}, {
                            "lat": 46.69532878720102,
                            "lng": 15.935808420181274
                        }, {"lat": 46.69387175103675, "lng": 15.937868356704714}, {
                            "lat": 46.69338238411227,
                            "lng": 15.938571095466616
                        }, {"lat": 46.69207247789248, "lng": 15.940572023391725}, {
                            "lat": 46.69182594704592,
                            "lng": 15.94093680381775
                        }, {"lat": 46.69170820057288, "lng": 15.941124558448793}, {
                            "lat": 46.691130503220094,
                            "lng": 15.942181348800661
                        }, {"lat": 46.69072942307978, "lng": 15.942894816398622}, {
                            "lat": 46.69064111145614,
                            "lng": 15.943077206611635
                        }, {"lat": 46.689960371177335, "lng": 15.944815278053285}, {
                            "lat": 46.68906987634676,
                            "lng": 15.947073698043825
                        }, {"lat": 46.688528948765544, "lng": 15.948849320411684}, {
                            "lat": 46.68799537543612,
                            "lng": 15.9511935710907
                        }, {"lat": 46.68744339747983, "lng": 15.954970121383669}, {
                            "lat": 46.687292522523556,
                            "lng": 15.956783294677736
                        }, {"lat": 46.68699813115158, "lng": 15.960119962692263}, {
                            "lat": 46.68667429878932,
                            "lng": 15.965538024902346
                        }, {"lat": 46.68469446326861, "lng": 15.983026027679445}, {
                            "lat": 46.68426021454826,
                            "lng": 15.985316634178163
                        }, {"lat": 46.681408070525634, "lng": 15.99613130092621}, {
                            "lat": 46.680583680848834,
                            "lng": 15.99837899208069
                        }, {"lat": 46.67974823740619, "lng": 15.999966859817507}, {
                            "lat": 46.67915937083044,
                            "lng": 16.000835895538334
                        }, {"lat": 46.67843063955784, "lng": 16.001769304275516}, {
                            "lat": 46.677348565900544,
                            "lng": 16.00287973880768
                        }, {"lat": 46.675600271858144, "lng": 16.004220843315128}, {
                            "lat": 46.674135341928725,
                            "lng": 16.004971861839298
                        }, {"lat": 46.66993909170044, "lng": 16.006275415420536}, {
                            "lat": 46.66808748471754,
                            "lng": 16.007289290428165
                        }, {"lat": 46.666975753828424, "lng": 16.008211970329288}, {
                            "lat": 46.66594130819425,
                            "lng": 16.009311676025394
                        }, {"lat": 46.665138770899084, "lng": 16.01043820381165}, {
                            "lat": 46.664244185468206,
                            "lng": 16.011983156204227
                        }, {"lat": 46.66332381463708, "lng": 16.01441860198975}, {
                            "lat": 46.66293357267443,
                            "lng": 16.01616203784943
                        }, {"lat": 46.662819444775614, "lng": 16.018254160881046}, {
                            "lat": 46.66270899819255,
                            "lng": 16.020217537879947
                        }, {"lat": 46.66253596475874, "lng": 16.02142453193665}, {
                            "lat": 46.66227457277661,
                            "lng": 16.022508144378666
                        }, {"lat": 46.66150511580351, "lng": 16.024503707885746}, {
                            "lat": 46.659914624573396,
                            "lng": 16.027464866638187
                        }, {"lat": 46.65885058927442, "lng": 16.030340194702152}, {
                            "lat": 46.65787489836542,
                            "lng": 16.033489108085636
                        }, {"lat": 46.65683659662789, "lng": 16.036707758903507}, {
                            "lat": 46.656144384393336,
                            "lng": 16.03810787200928
                        }, {"lat": 46.651070345486055, "lng": 16.035742163658146}, {
                            "lat": 46.650289681861224,
                            "lng": 16.03609621524811
                        }, {"lat": 46.649029480114855, "lng": 16.03862285614014}, {
                            "lat": 46.64757856039825,
                            "lng": 16.042066812515262
                        }, {"lat": 46.64751227379627, "lng": 16.04538202285767}, {
                            "lat": 46.64684940330892,
                            "lng": 16.045714616775516
                        }, {"lat": 46.645663581396235, "lng": 16.045939922332767}, {
                            "lat": 46.636374971508886,
                            "lng": 16.064972877502445
                        }, {"lat": 46.63565015255271, "lng": 16.065664887428287}, {
                            "lat": 46.63515657486916,
                            "lng": 16.06634616851807
                        }, {"lat": 46.634847165667466, "lng": 16.066533923149112}, {
                            "lat": 46.634640891883535,
                            "lng": 16.066941618919376
                        }, {"lat": 46.634220974965395, "lng": 16.067794561386112}, {
                            "lat": 46.633307460240026,
                            "lng": 16.070900559425358
                        }, {"lat": 46.63076574539329, "lng": 16.07585728168488}, {
                            "lat": 46.630669968297255,
                            "lng": 16.077193021774296
                        }, {"lat": 46.62998478874396, "lng": 16.07810497283936}, {
                            "lat": 46.62918540163557,
                            "lng": 16.07858240604401
                        }, {"lat": 46.62822759508294, "lng": 16.080175638198856}, {
                            "lat": 46.627586592000256,
                            "lng": 16.080985665321354
                        }, {"lat": 46.6262382503948, "lng": 16.083083152771}, {
                            "lat": 46.62531723272483,
                            "lng": 16.08453691005707
                        }, {"lat": 46.624867770412514, "lng": 16.085255742073063}, {
                            "lat": 46.62382515084428,
                            "lng": 16.086891889572147
                        }, {"lat": 46.623165674389824, "lng": 16.087937951087955}, {
                            "lat": 46.62298883017574,
                            "lng": 16.088222265243534
                        }, {"lat": 46.62277882692126, "lng": 16.08852803707123}, {
                            "lat": 46.62207144154439,
                            "lng": 16.089649200439457
                        }, {"lat": 46.62175827319154, "lng": 16.09010517597199}, {
                            "lat": 46.62145615612265,
                            "lng": 16.0905396938324
                        }, {"lat": 46.62126456881519, "lng": 16.09080255031586}, {
                            "lat": 46.62088139216641,
                            "lng": 16.091360449790958
                        }, {"lat": 46.62027346211133, "lng": 16.092240214347843}, {
                            "lat": 46.61968026317483,
                            "lng": 16.09309315681458
                        }, {"lat": 46.61954025253992, "lng": 16.09330773353577}, {
                            "lat": 46.61929339080178,
                            "lng": 16.093710064888004
                        }, {"lat": 46.61882177121774, "lng": 16.094461083412174}, {
                            "lat": 46.618342778373034,
                            "lng": 16.095260381698612
                        }, {"lat": 46.617727450577725, "lng": 16.096252799034122}, {
                            "lat": 46.617384779971815,
                            "lng": 16.09682142734528
                        }, {"lat": 46.616997891197535, "lng": 16.097449064254764}, {
                            "lat": 46.61516658015805,
                            "lng": 16.100442409515384
                        }, {"lat": 46.614823893346376, "lng": 16.10101640224457}, {
                            "lat": 46.61412745862818,
                            "lng": 16.102164387702945
                        }, {"lat": 46.613508398026475, "lng": 16.103172898292545}, {
                            "lat": 46.61123107848908,
                            "lng": 16.106917262077335
                        }, {"lat": 46.611127896695514, "lng": 16.107121109962467}, {
                            "lat": 46.61102839977984,
                            "lng": 16.10731959342957
                        }, {"lat": 46.61085520100912, "lng": 16.107700467109684}, {
                            "lat": 46.61060829967633,
                            "lng": 16.108210086822513
                        }, {"lat": 46.61004447599507, "lng": 16.108483672142032}, {
                            "lat": 46.60769330482898,
                            "lng": 16.1087840795517
                        }, {"lat": 46.606057006361375, "lng": 16.10728204250336}, {
                            "lat": 46.60489977445332,
                            "lng": 16.105855107307438
                        }, {"lat": 46.604155300094995, "lng": 16.107346415519718}, {
                            "lat": 46.60367249194822,
                            "lng": 16.10829591751099
                        }, {"lat": 46.60278057550575, "lng": 16.11006081104279}, {
                            "lat": 46.602441495981125,
                            "lng": 16.110742092132572
                        }, {"lat": 46.60134315860299, "lng": 16.112925410270694}, {
                            "lat": 46.60171173067524,
                            "lng": 16.11575782299042
                        }, {"lat": 46.60215032817401, "lng": 16.11903548240662}, {
                            "lat": 46.60330024761501,
                            "lng": 16.12774729728699
                        }, {"lat": 46.60320442192717, "lng": 16.128203272819523}, {
                            "lat": 46.60335921718484,
                            "lng": 16.131330728530887
                        }, {"lat": 46.60176333056528, "lng": 16.142810583114628}, {
                            "lat": 46.601693302131174,
                            "lng": 16.144913434982303
                        }, {"lat": 46.601505330623695, "lng": 16.14447891712189}, {
                            "lat": 46.599632951379654,
                            "lng": 16.146410107612613
                        }, {"lat": 46.59936019782273, "lng": 16.1467856168747}, {
                            "lat": 46.59839449176054,
                            "lng": 16.148158907890323
                        }, {"lat": 46.59797429367266, "lng": 16.150181293487552}, {
                            "lat": 46.59786002871684,
                            "lng": 16.15394175052643
                        }, {"lat": 46.59762412611029, "lng": 16.155626177787784}, {
                            "lat": 46.59769784578514,
                            "lng": 16.155636906623844
                        }, {"lat": 46.59768310185821, "lng": 16.155889034271244}, {
                            "lat": 46.59717074790469,
                            "lng": 16.15799725055695
                        }, {"lat": 46.59616076980948, "lng": 16.16025567054749}, {
                            "lat": 46.59519500672582,
                            "lng": 16.161800622940067
                        }, {"lat": 46.59407071886, "lng": 16.163152456283573}, {
                            "lat": 46.59320813465231,
                            "lng": 16.164911985397342
                        }, {"lat": 46.5930090748085, "lng": 16.165335774421695}, {
                            "lat": 46.59282844579895,
                            "lng": 16.166065335273746
                        }, {"lat": 46.592496676662044, "lng": 16.16748690605164}, {
                            "lat": 46.59227549610912,
                            "lng": 16.168420314788822
                        }, {"lat": 46.592109610102035, "lng": 16.169627308845524}, {
                            "lat": 46.592194396346855,
                            "lng": 16.169777512550358
                        }, {"lat": 46.59202113735732, "lng": 16.171563863754276}, {
                            "lat": 46.5915197890759,
                            "lng": 16.173827648162845
                        }, {"lat": 46.590959453155534, "lng": 16.175104379653934}, {
                            "lat": 46.59021847373393,
                            "lng": 16.176289916038517
                        }, {"lat": 46.58915675421999, "lng": 16.178317666053776}, {
                            "lat": 46.58884708211085,
                            "lng": 16.17891311645508
                        }, {"lat": 46.58838994385906, "lng": 16.18070483207703}, {
                            "lat": 46.58819086631856,
                            "lng": 16.182003021240238
                        }, {"lat": 46.5881687465467, "lng": 16.182335615158085}, {
                            "lat": 46.588412063540645,
                            "lng": 16.18243217468262
                        }, {"lat": 46.58398794759145, "lng": 16.192994713783268}, {
                            "lat": 46.582505788008945,
                            "lng": 16.195135116577152
                        }, {"lat": 46.58134436599042, "lng": 16.196792721748356}, {
                            "lat": 46.580688059371724,
                            "lng": 16.19772076606751
                        }, {"lat": 46.579928503592186, "lng": 16.198788285255436}, {
                            "lat": 46.579788390198516,
                            "lng": 16.198498606681827
                        }, {"lat": 46.57939385843448, "lng": 16.198831200599674}, {
                            "lat": 46.57867853184031,
                            "lng": 16.199818253517154
                        }, {"lat": 46.578177060147596, "lng": 16.200767755508426}, {
                            "lat": 46.57805537881706,
                            "lng": 16.200617551803592
                        }, {"lat": 46.577321597732606, "lng": 16.202092766761783}, {
                            "lat": 46.575865068173854,
                            "lng": 16.204286813735965
                        }, {"lat": 46.57382217346046, "lng": 16.20738744735718}, {
                            "lat": 46.57002011939556,
                            "lng": 16.21314883232117
                        }, {"lat": 46.56808764446383, "lng": 16.216067075729374}, {
                            "lat": 46.56135294763762,
                            "lng": 16.225830316543583
                        }, {"lat": 46.56040869549674, "lng": 16.22690856456757}, {
                            "lat": 46.559375900901074,
                            "lng": 16.228083372116092
                        }, {"lat": 46.558258247458696, "lng": 16.230438351631168}, {
                            "lat": 46.55700039872446,
                            "lng": 16.232460737228397
                        }, {"lat": 46.556004427430196, "lng": 16.234027147293094}, {
                            "lat": 46.55472439303461,
                            "lng": 16.237031221389774
                        }, {"lat": 46.55418581046038, "lng": 16.237739324569706}, {
                            "lat": 46.55397922942459,
                            "lng": 16.238329410552982
                        }, {"lat": 46.551507573902526, "lng": 16.242679953575138}, {
                            "lat": 46.55027908234122,
                            "lng": 16.24483108520508
                        }, {"lat": 46.55008355409227, "lng": 16.245244145393375}, {
                            "lat": 46.54952647918119,
                            "lng": 16.246456503868107
                        }, {"lat": 46.54804706706163, "lng": 16.249750256538395}, {
                            "lat": 46.54679636052519,
                            "lng": 16.252561211586002
                        }, {"lat": 46.545936713658726, "lng": 16.254470944404606}, {
                            "lat": 46.54477450848358,
                            "lng": 16.257051229476932
                        }, {"lat": 46.54410300081898, "lng": 16.258226037025455}, {
                            "lat": 46.54352372654507,
                            "lng": 16.258955597877506
                        }, {"lat": 46.54173789943881, "lng": 16.262539029121402}, {
                            "lat": 46.541265604762366,
                            "lng": 16.263461709022526
                        }, {"lat": 46.5389188296654, "lng": 16.267753243446354}, {
                            "lat": 46.53804060787203,
                            "lng": 16.26933574676514
                        }, {"lat": 46.53736163674988, "lng": 16.27086460590363}, {
                            "lat": 46.53718451245221,
                            "lng": 16.270494461059574
                        }, {"lat": 46.5349777904738, "lng": 16.270236968994144}, {
                            "lat": 46.53373047316669,
                            "lng": 16.26984000205994
                        }, {"lat": 46.533320844961835, "lng": 16.270000934600834}, {
                            "lat": 46.531907419415006,
                            "lng": 16.27236127853394
                        }, {"lat": 46.53203658515663, "lng": 16.273664832115177}, {
                            "lat": 46.532379795491835,
                            "lng": 16.27460896968842
                        }, {"lat": 46.534557100856034, "lng": 16.277768611907963}, {
                            "lat": 46.53388915713649,
                            "lng": 16.280273795127872
                        }, {"lat": 46.53360869213122, "lng": 16.28133058547974}, {
                            "lat": 46.533032996266755,
                            "lng": 16.28303110599518
                        }, {"lat": 46.533250728112556, "lng": 16.28358364105225}, {
                            "lat": 46.52968202997214,
                            "lng": 16.286453604698185
                        }, {"lat": 46.52873722678215, "lng": 16.28782153129578}, {
                            "lat": 46.528183623527084,
                            "lng": 16.289463043212894
                        }, {"lat": 46.528058139338206, "lng": 16.293137669563297}, {
                            "lat": 46.52769275725492,
                            "lng": 16.294494867324833
                        }, {"lat": 46.526777445892506, "lng": 16.29630267620087}, {
                            "lat": 46.525692339055645,
                            "lng": 16.29777252674103
                        }, {"lat": 46.5247585392894, "lng": 16.29902780056}, {
                            "lat": 46.52424549622218,
                            "lng": 16.30007386207581
                        }, {"lat": 46.524005582333906, "lng": 16.300862431526188}, {
                            "lat": 46.52394283544986,
                            "lng": 16.301897764205936
                        }, {"lat": 46.524053565196326, "lng": 16.3037645816803}, {
                            "lat": 46.524762230229165,
                            "lng": 16.307122707366947
                        }, {"lat": 46.52490248575436, "lng": 16.30853354930878}, {
                            "lat": 46.52424180524732,
                            "lng": 16.311741471290592
                        }, {"lat": 46.52440051694036, "lng": 16.313093304634098}, {
                            "lat": 46.52456661007611,
                            "lng": 16.313699483871464
                        }, {"lat": 46.5236143358741, "lng": 16.313098669052128}, {
                            "lat": 46.52330428950858,
                            "lng": 16.313238143920902
                        }, {"lat": 46.523186176142005, "lng": 16.313849687576297}, {
                            "lat": 46.52342609364893,
                            "lng": 16.31482601165772
                        }, {"lat": 46.52001916575324, "lng": 16.31457388401032}, {
                            "lat": 46.52007453465322,
                            "lng": 16.313479542732242
                        }, {"lat": 46.520100373453936, "lng": 16.312900185585026}, {
                            "lat": 46.52034768706742,
                            "lng": 16.31077587604523
                        }, {"lat": 46.51868291252123, "lng": 16.309472322463993}, {
                            "lat": 46.51882318374129,
                            "lng": 16.309155821800235
                        }, {"lat": 46.517228500108345, "lng": 16.307723522186283}, {
                            "lat": 46.51704392722161,
                            "lng": 16.30780935287476
                        }, {"lat": 46.51680767301171, "lng": 16.307734251022342}, {
                            "lat": 46.51680398153155,
                            "lng": 16.30852282047272
                        }, {"lat": 46.51666001360894, "lng": 16.308351159095768}, {
                            "lat": 46.51667477956727,
                            "lng": 16.307911276817325
                        }, {"lat": 46.515740824803636, "lng": 16.3072943687439}, {
                            "lat": 46.515574704685974,
                            "lng": 16.307643055915836
                        }, {"lat": 46.51505419169553, "lng": 16.307905912399296}, {
                            "lat": 46.51381010853435,
                            "lng": 16.307723522186283
                        }, {"lat": 46.51350369792483, "lng": 16.306993961334232}, {
                            "lat": 46.51350369792483,
                            "lng": 16.30676329135895
                        }, {"lat": 46.5140279174352, "lng": 16.306993961334232}, {
                            "lat": 46.51414605071121,
                            "lng": 16.306382417678837
                        }, {"lat": 46.51355538176322, "lng": 16.305937170982364}, {
                            "lat": 46.51363290742866,
                            "lng": 16.30526125431061
                        }, {"lat": 46.51298316509776, "lng": 16.30529880523682}, {
                            "lat": 46.51299054857696,
                            "lng": 16.30455851554871
                        }, {"lat": 46.51282442005204, "lng": 16.30351781845093}, {
                            "lat": 46.51327112004379,
                            "lng": 16.30327105522156
                        }, {"lat": 46.51322681938217, "lng": 16.302654147148136}, {
                            "lat": 46.513086533715466,
                            "lng": 16.302187442779545
                        }, {"lat": 46.512296497146345, "lng": 16.302187442779545}, {
                            "lat": 46.51206022229663,
                            "lng": 16.301731467247013
                        }, {"lat": 46.51198269438776, "lng": 16.300234794616703}, {
                            "lat": 46.51176118546728,
                            "lng": 16.299853920936588
                        }, {"lat": 46.5121414418921, "lng": 16.2993335723877}, {
                            "lat": 46.51228911357285,
                            "lng": 16.29883468151093
                        }, {"lat": 46.51237771638871, "lng": 16.298426985740665}, {
                            "lat": 46.51215251728205,
                            "lng": 16.297482848167423
                        }, {"lat": 46.51272474269335, "lng": 16.296157836914066}, {
                            "lat": 46.513060691579476,
                            "lng": 16.295009851455692
                        }, {"lat": 46.51381380021791, "lng": 16.295208334922794}, {
                            "lat": 46.51421619222233,
                            "lng": 16.29578232765198
                        }, {"lat": 46.514744096480335, "lng": 16.29556238651276}, {
                            "lat": 46.51382487526708,
                            "lng": 16.294462680816654
                        }, {"lat": 46.513045924639115, "lng": 16.294135451316837}, {
                            "lat": 46.51286872104167,
                            "lng": 16.293872594833378
                        }, {"lat": 46.51355907346408, "lng": 16.292461752891544}, {
                            "lat": 46.51436754991159,
                            "lng": 16.29332005977631
                        }, {"lat": 46.51490652752801, "lng": 16.291989684104923}, {
                            "lat": 46.5155082564967,
                            "lng": 16.292188167572025
                        }, {"lat": 46.51580358116037, "lng": 16.29253685474396}, {
                            "lat": 46.51609521269075,
                            "lng": 16.290884613990787
                        }, {"lat": 46.51610628727498, "lng": 16.29009068012238}, {
                            "lat": 46.51598815826015,
                            "lng": 16.289741992950443
                        }, {"lat": 46.515648535912064, "lng": 16.289908289909366}, {
                            "lat": 46.51482162045,
                            "lng": 16.28956496715546
                        }, {"lat": 46.51502096729282, "lng": 16.288685202598575}, {
                            "lat": 46.51474778810046,
                            "lng": 16.28832578659058
                        }, {"lat": 46.51478470428777, "lng": 16.288164854049686}, {
                            "lat": 46.51529045352894,
                            "lng": 16.288020014762882
                        }, {"lat": 46.51536797671924, "lng": 16.287757158279422}, {
                            "lat": 46.51513171522285,
                            "lng": 16.287392377853397
                        }, {"lat": 46.51529045352894, "lng": 16.286174654960636}, {
                            "lat": 46.51568914304367,
                            "lng": 16.285868883132938
                        }, {"lat": 46.51516863114936, "lng": 16.28555774688721}, {
                            "lat": 46.51445614933897,
                            "lng": 16.284897923469547
                        }, {"lat": 46.515102182463565, "lng": 16.28383040428162}, {
                            "lat": 46.51555624686376,
                            "lng": 16.283975243568424
                        }, {"lat": 46.5163388530225, "lng": 16.283733844757084}, {
                            "lat": 46.51577404876624,
                            "lng": 16.282516121864322
                        }, {"lat": 46.51532736934764, "lng": 16.282162070274357}, {
                            "lat": 46.51560054562651,
                            "lng": 16.281899213790897
                        }, {"lat": 46.51566699370291, "lng": 16.28107845783234}, {
                            "lat": 46.5152018554618,
                            "lng": 16.278970241546634
                        }, {"lat": 46.51523507975391, "lng": 16.278385519981388}, {
                            "lat": 46.51580358116037,
                            "lng": 16.278444528579715
                        }, {"lat": 46.51546395765874, "lng": 16.276888847351078}, {
                            "lat": 46.515065266491916,
                            "lng": 16.27736628055573
                        }, {"lat": 46.5149176023545, "lng": 16.276996135711673}, {
                            "lat": 46.51485115336177,
                            "lng": 16.276030540466312
                        }, {"lat": 46.51426418373044, "lng": 16.275901794433597}, {
                            "lat": 46.514120209079046,
                            "lng": 16.27536535263062
                        }, {"lat": 46.51415712569268, "lng": 16.275022029876713}, {
                            "lat": 46.51447460753484,
                            "lng": 16.274737715721134
                        }, {"lat": 46.51467764727545, "lng": 16.274662613868717}, {
                            "lat": 46.514843770135336,
                            "lng": 16.274785995483402
                        }, {"lat": 46.51495082682039, "lng": 16.274678707122806}, {
                            "lat": 46.51544919137138,
                            "lng": 16.27508103847504
                        }, {"lat": 46.51555624686376, "lng": 16.27535462379456}, {
                            "lat": 46.516154277113905,
                            "lng": 16.274979114532474
                        }, {"lat": 46.51610628727498, "lng": 16.27462506294251}, {
                            "lat": 46.516619407202825,
                            "lng": 16.27462506294251
                        }, {"lat": 46.51671169444555, "lng": 16.274174451828006}, {
                            "lat": 46.51655296029102,
                            "lng": 16.273981332778934
                        }, {"lat": 46.51654557729582, "lng": 16.273396611213688}, {
                            "lat": 46.516061988924505,
                            "lng": 16.273241043090824
                        }, {"lat": 46.51610997880254, "lng": 16.272999644279484}, {
                            "lat": 46.51656772627844,
                            "lng": 16.272667050361637
                        }, {"lat": 46.51673753484544, "lng": 16.271883845329288}, {
                            "lat": 46.51744260387156,
                            "lng": 16.272286176681522
                        }, {"lat": 46.518143972360036, "lng": 16.273750662803653}, {
                            "lat": 46.51780067207309,
                            "lng": 16.271975040435795
                        }, {"lat": 46.51757918686596, "lng": 16.272001862525943}, {
                            "lat": 46.516789215608334,
                            "lng": 16.271411776542667
                        }, {"lat": 46.51533844408835, "lng": 16.270741224288944}, {
                            "lat": 46.515393817758095,
                            "lng": 16.26833260059357
                        }, {"lat": 46.51527199561021, "lng": 16.268643736839298}, {
                            "lat": 46.515028350495186,
                            "lng": 16.267994642257694
                        }, {"lat": 46.51533475250838, "lng": 16.266020536422733}, {
                            "lat": 46.51481054560397,
                            "lng": 16.265929341316227
                        }, {"lat": 46.513965159027876, "lng": 16.266401410102848}, {
                            "lat": 46.51262875691585,
                            "lng": 16.266798377037052
                        }, {"lat": 46.512591839264005, "lng": 16.266680359840397}, {
                            "lat": 46.51237033282624,
                            "lng": 16.26708805561066
                        }, {"lat": 46.512049146887854, "lng": 16.266948580741886}, {
                            "lat": 46.5119383926759,
                            "lng": 16.267141699790958
                        }, {"lat": 46.511270170808295, "lng": 16.26710951328278}, {
                            "lat": 46.51130708935795,
                            "lng": 16.267254352569584
                        }, {"lat": 46.5110375833689, "lng": 16.26736164093018}, {
                            "lat": 46.51037304234329,
                            "lng": 16.26711487770081
                        }, {"lat": 46.51016629458929, "lng": 16.267415285110477}, {
                            "lat": 46.509590350270585,
                            "lng": 16.267769336700443
                        }, {"lat": 46.50881872299625, "lng": 16.267141699790958}, {
                            "lat": 46.508597201180805,
                            "lng": 16.267281174659733
                        }, {"lat": 46.507508039123394, "lng": 16.267286539077762}, {
                            "lat": 46.50743050472113,
                            "lng": 16.267479658126835
                        }, {"lat": 46.50686191572522, "lng": 16.267227530479435}, {
                            "lat": 46.506872992191035,
                            "lng": 16.26711487770081
                        }, {"lat": 46.506444700536015, "lng": 16.266771554946903}, {
                            "lat": 46.50621947684633,
                            "lng": 16.26681447029114
                        }, {"lat": 46.506068096464986, "lng": 16.26705586910248}, {
                            "lat": 46.505728412123716,
                            "lng": 16.2671309709549
                        }, {"lat": 46.505104421226925, "lng": 16.266900300979618}, {
                            "lat": 46.504879191984244,
                            "lng": 16.266980767250065
                        }, {"lat": 46.50437334585756, "lng": 16.265226602554325}, {
                            "lat": 46.50426257600452,
                            "lng": 16.264266371726993
                        }, {"lat": 46.50457642333522, "lng": 16.264024972915653}, {
                            "lat": 46.50486442282099,
                            "lng": 16.263429522514347
                        }, {"lat": 46.504794269240655, "lng": 16.26330614089966}, {
                            "lat": 46.503871187382856,
                            "lng": 16.26331686973572
                        }, {"lat": 46.50331733074476, "lng": 16.262726783752445}, {
                            "lat": 46.50254930686952,
                            "lng": 16.262442469596866
                        }, {"lat": 46.501940049443654, "lng": 16.261450052261356}, {
                            "lat": 46.500968915917,
                            "lng": 16.26037180423737
                        }, {"lat": 46.50104276698471, "lng": 16.259245276451114}, {
                            "lat": 46.50094676057714,
                            "lng": 16.258258223533634
                        }, {"lat": 46.501061229735946, "lng": 16.25687956809998}, {
                            "lat": 46.50076582496358,
                            "lng": 16.25686883926392
                        }, {"lat": 46.50089506474898, "lng": 16.255050301551822}, {
                            "lat": 46.500655047760155,
                            "lng": 16.255023479461673
                        }, {"lat": 46.49998668717734, "lng": 16.254240274429325}, {
                            "lat": 46.498949050325486,
                            "lng": 16.255769133567814
                        }, {"lat": 46.49864994136404, "lng": 16.255769133567814}, {
                            "lat": 46.50043349267618,
                            "lng": 16.252282261848453
                        }, {"lat": 46.50021193668941, "lng": 16.25207304954529}, {
                            "lat": 46.500079002663995,
                            "lng": 16.251713633537296
                        }, {"lat": 46.49998299455463, "lng": 16.250667572021488}, {
                            "lat": 46.49979097782728,
                            "lng": 16.25063538551331
                        }, {"lat": 46.49982051890638, "lng": 16.250297427177433}, {
                            "lat": 46.49957311187313,
                            "lng": 16.24914944171906
                        }, {"lat": 46.49930724037354, "lng": 16.24859154224396}, {
                            "lat": 46.49895274301842,
                            "lng": 16.246966123580936
                        }, {"lat": 46.49863147779401, "lng": 16.246461868286136}, {
                            "lat": 46.4978818515552,
                            "lng": 16.246263384819034
                        }, {"lat": 46.498273283294296, "lng": 16.2438976764679}, {
                            "lat": 46.49846530538136,
                            "lng": 16.244026422500614
                        }, {"lat": 46.49863517050852, "lng": 16.243221759796146}, {
                            "lat": 46.49852808168582,
                            "lng": 16.241730451583866
                        }, {"lat": 46.49668907520149, "lng": 16.241435408592228}, {
                            "lat": 46.49620531015155,
                            "lng": 16.241521239280704
                        }, {"lat": 46.4959578866687, "lng": 16.24116182327271}, {
                            "lat": 46.49567722583281,
                            "lng": 16.2411242723465
                        }, {"lat": 46.4953411695053, "lng": 16.24133348464966}, {
                            "lat": 46.49228333080265,
                            "lng": 16.241827011108402
                        }, {"lat": 46.49123077408267, "lng": 16.24134421348572}, {
                            "lat": 46.49072110771962,
                            "lng": 16.241580247879032
                        }, {"lat": 46.490444113127836, "lng": 16.242030858993534}, {
                            "lat": 46.49000461214739,
                            "lng": 16.24210596084595
                        }, {"lat": 46.490071091515254, "lng": 16.240770220756534}, {
                            "lat": 46.48986057323828,
                            "lng": 16.238769292831424
                        }, {"lat": 46.49002677194572, "lng": 16.238034367561344}, {
                            "lat": 46.49020035672016,
                            "lng": 16.237744688987735
                        }, {"lat": 46.48959834757824, "lng": 16.237047314643863}, {
                            "lat": 46.48962050754206,
                            "lng": 16.236489415168766
                        }, {"lat": 46.48914406632997, "lng": 16.236054897308353}, {
                            "lat": 46.48843493679765,
                            "lng": 16.234788894653324
                        }, {"lat": 46.48771102419747, "lng": 16.232203245162967}, {
                            "lat": 46.48758914000595,
                            "lng": 16.231779456138614
                        }, {"lat": 46.487301049013084, "lng": 16.230754852294925}, {
                            "lat": 46.487120067993665,
                            "lng": 16.230089664459232
                        }, {"lat": 46.486964940926306, "lng": 16.22961223125458}, {
                            "lat": 46.48701664999792,
                            "lng": 16.228748559951786
                        }, {"lat": 46.48702773050686, "lng": 16.228523254394535}, {
                            "lat": 46.487441401225844,
                            "lng": 16.227225065231327
                        }, {"lat": 46.487611300788366, "lng": 16.22590005397797}, {
                            "lat": 46.48766670270487,
                            "lng": 16.22537434101105
                        }, {"lat": 46.488194864809195, "lng": 16.224768161773685}, {
                            "lat": 46.487278888104285,
                            "lng": 16.222488284111027
                        }, {"lat": 46.4874487881744, "lng": 16.221790909767154}, {
                            "lat": 46.48732320991288,
                            "lng": 16.221533417701725
                        }, {"lat": 46.48588642622865, "lng": 16.22113108634949}, {
                            "lat": 46.48475987263321,
                            "lng": 16.220310330390934
                        }, {"lat": 46.48445699194961, "lng": 16.21994018554688}, {
                            "lat": 46.48435356889095,
                            "lng": 16.219253540039066
                        }, {"lat": 46.484859601269775, "lng": 16.218851208686832}, {
                            "lat": 46.484937167860686,
                            "lng": 16.218486428260807
                        }, {"lat": 46.484630594498945, "lng": 16.218094825744632}, {
                            "lat": 46.48402113629924,
                            "lng": 16.21793925762177
                        }, {"lat": 46.4838253694893, "lng": 16.21766030788422}, {
                            "lat": 46.48365915183357,
                            "lng": 16.21715605258942
                        }, {"lat": 46.48278003667504, "lng": 16.215460896492008}, {
                            "lat": 46.48235524905004,
                            "lng": 16.214071512222294
                        }, {"lat": 46.48239588105328, "lng": 16.213561892509464}, {
                            "lat": 46.48229614790025,
                            "lng": 16.213551163673404
                        }, {"lat": 46.48197847812021, "lng": 16.214280724525455}, {
                            "lat": 46.48181225482166,
                            "lng": 16.215037107467655
                        }, {"lat": 46.48082229221395, "lng": 16.214962005615238}, {
                            "lat": 46.48016476978079,
                            "lng": 16.215063929557804
                        }, {"lat": 46.47922279964049, "lng": 16.215809583663944}, {
                            "lat": 46.47647067581059,
                            "lng": 16.21592223644257
                        }, {"lat": 46.4756616357552, "lng": 16.215262413024906}, {
                            "lat": 46.47442403979535,
                            "lng": 16.215986609458927
                        }, {"lat": 46.47379230085828, "lng": 16.216050982475284}, {
                            "lat": 46.473496747960404,
                            "lng": 16.214017868041996
                        }, {"lat": 46.47321597122067, "lng": 16.213014721870426}, {
                            "lat": 46.47297952652684,
                            "lng": 16.212612390518192
                        }, {"lat": 46.47181206579331, "lng": 16.20915234088898}, {
                            "lat": 46.47174556411154,
                            "lng": 16.208771467208866
                        }, {"lat": 46.47274677527804, "lng": 16.208733916282657}, {
                            "lat": 46.473234443419116,
                            "lng": 16.20843887329102
                        }, {"lat": 46.47337483192239, "lng": 16.20820820331574}, {
                            "lat": 46.47338591520986,
                            "lng": 16.207838058471683
                        }, {"lat": 46.473699940750116, "lng": 16.207349896430973}, {
                            "lat": 46.47379599525935,
                            "lng": 16.205558180809025
                        }, {"lat": 46.47546583886559, "lng": 16.20566010475159}, {
                            "lat": 46.47597195391077,
                            "lng": 16.205815672874454
                        }, {"lat": 46.47648545268606, "lng": 16.205332875251774}, {
                            "lat": 46.47743855267688,
                            "lng": 16.20516121387482
                        }, {"lat": 46.47776363722779, "lng": 16.204748153686527}, {
                            "lat": 46.478158907872135,
                            "lng": 16.203305125236515
                        }, {"lat": 46.47930406829537, "lng": 16.201041340827945}, {
                            "lat": 46.47949985137458,
                            "lng": 16.19989335536957
                        }, {"lat": 46.47970302174968, "lng": 16.199850440025333}, {
                            "lat": 46.479994846961034,
                            "lng": 16.199276447296146
                        }, {"lat": 46.480238649102084, "lng": 16.199254989624027}, {
                            "lat": 46.480715168314404,
                            "lng": 16.198305487632755
                        }, {"lat": 46.48070408651966, "lng": 16.19795680046082}, {
                            "lat": 46.480375325582074,
                            "lng": 16.197624206542972
                        }, {"lat": 46.48029775248693, "lng": 16.197044849395756}, {
                            "lat": 46.48012413611127,
                            "lng": 16.196728348731998
                        }, {"lat": 46.47990619136613, "lng": 16.195333600044254}, {
                            "lat": 46.48005395061069,
                            "lng": 16.19491517543793
                        }, {"lat": 46.48002439879388, "lng": 16.194604039192203}, {
                            "lat": 46.48045289856666,
                            "lng": 16.193869113922123
                        }, {"lat": 46.48072994403724, "lng": 16.193075180053714}, {
                            "lat": 46.48068192292339,
                            "lng": 16.192581653594974
                        }, {"lat": 46.48076688332674, "lng": 16.192297339439396}, {
                            "lat": 46.4805747987475,
                            "lng": 16.1919379234314
                        }, {"lat": 46.48024234306552, "lng": 16.190800666809086}, {
                            "lat": 46.48011305419617,
                            "lng": 16.191063523292545
                        }, {"lat": 46.47928929218534, "lng": 16.191610693931583}, {
                            "lat": 46.478905111915836,
                            "lng": 16.191133260726932
                        }, {"lat": 46.478302977766845, "lng": 16.191852092742923}, {
                            "lat": 46.477408999439604,
                            "lng": 16.19217395782471
                        }, {"lat": 46.47616036049902, "lng": 16.193354129791263}, {
                            "lat": 46.47584265489433,
                            "lng": 16.193879842758182
                        }, {"lat": 46.475606221612644, "lng": 16.193869113922123}, {
                            "lat": 46.47479347437198,
                            "lng": 16.19470596313477
                        }, {"lat": 46.47460136870512, "lng": 16.194046139717106}, {
                            "lat": 46.47506316002928,
                            "lng": 16.193268299102787
                        }, {"lat": 46.47504838276762, "lng": 16.193021535873417}, {
                            "lat": 46.474649395185416,
                            "lng": 16.19215250015259
                        }, {"lat": 46.474520093026534, "lng": 16.19141221046448}, {
                            "lat": 46.47412479594982,
                            "lng": 16.191637516021732
                        }, {"lat": 46.47369255193468, "lng": 16.19295716285706}, {
                            "lat": 46.47305341560402,
                            "lng": 16.19266211986542
                        }, {"lat": 46.47296474869939, "lng": 16.19190573692322}, {
                            "lat": 46.472517717521676,
                            "lng": 16.19198083877564
                        }, {"lat": 46.47161994960211, "lng": 16.19294106960297}, {
                            "lat": 46.47097339974643,
                            "lng": 16.193633079528812
                        }, {"lat": 46.47053374145743, "lng": 16.194110512733463}, {
                            "lat": 46.46912976684059,
                            "lng": 16.195043921470646
                        }, {"lat": 46.46886744125207, "lng": 16.195285320281986}, {
                            "lat": 46.46826519603351,
                            "lng": 16.19621872901917
                        }, {"lat": 46.467928970344346, "lng": 16.196320652961735}, {
                            "lat": 46.46722325912162,
                            "lng": 16.19721651077271
                        }, {"lat": 46.467097634175026, "lng": 16.19815528392792}, {
                            "lat": 46.466229336175914,
                            "lng": 16.1979353427887
                        }, {"lat": 46.46649906427136, "lng": 16.19740426540375}, {
                            "lat": 46.46637343765348,
                            "lng": 16.19694828987122
                        }, {"lat": 46.46662838548695, "lng": 16.19596660137177}, {
                            "lat": 46.466912891079836,
                            "lng": 16.195381879806522
                        }, {"lat": 46.46715305698132, "lng": 16.195548176765445}, {
                            "lat": 46.4675816581111,
                            "lng": 16.195070743560795
                        }, {"lat": 46.46829105946204, "lng": 16.193515062332157}, {
                            "lat": 46.46831692287829,
                            "lng": 16.193085908889774
                        }, {"lat": 46.4686605356707, "lng": 16.192742586135868}, {
                            "lat": 46.46869378840646,
                            "lng": 16.192286610603336
                        }, {"lat": 46.46898567265973, "lng": 16.191760897636417}, {
                            "lat": 46.469100209091,
                            "lng": 16.190366148948673
                        }, {"lat": 46.46884527283454, "lng": 16.188853383064274}, {
                            "lat": 46.47141963542335,
                            "lng": 16.187764406204227
                        }, {"lat": 46.47280138416736, "lng": 16.18871927261353}, {
                            "lat": 46.473334193182374,
                            "lng": 16.18867635726929
                        }, {"lat": 46.47359280282825, "lng": 16.18865489959717}, {
                            "lat": 46.4739289935307,
                            "lng": 16.188622713088993
                        }, {"lat": 46.47391421596105, "lng": 16.18646085262299}, {
                            "lat": 46.47399179815698,
                            "lng": 16.185597181320194
                        }, {"lat": 46.473426553911274, "lng": 16.185318231582645}, {
                            "lat": 46.47285022040039,
                            "lng": 16.18448674678803
                        }, {"lat": 46.472713525018385, "lng": 16.184020042419437}, {
                            "lat": 46.47206698815393,
                            "lng": 16.182893514633182
                        }, {"lat": 46.472421660756304, "lng": 16.182834506034855}, {
                            "lat": 46.47260638515465,
                            "lng": 16.182636022567753
                        }, {"lat": 46.47281697020393, "lng": 16.182276606559757}, {
                            "lat": 46.47297583207035,
                            "lng": 16.18199229240418
                        }, {"lat": 46.47314947125436, "lng": 16.18170261383057}, {
                            "lat": 46.473367443062834,
                            "lng": 16.181353926658634
                        }, {"lat": 46.474224544081146, "lng": 16.180291771888736}, {
                            "lat": 46.474752836691316,
                            "lng": 16.180286407470707
                        }, {"lat": 46.47499296800055, "lng": 16.180136203765873}, {
                            "lat": 46.47524418115924,
                            "lng": 16.18022203445435
                        }, {"lat": 46.47545475600172, "lng": 16.180071830749515}, {
                            "lat": 46.47547322744024,
                            "lng": 16.179857254028324
                        }, {"lat": 46.47572074411174, "lng": 16.17944419384003}, {
                            "lat": 46.476093864130604,
                            "lng": 16.17920279502869
                        }, {"lat": 46.47626379913285, "lng": 16.1787360906601}, {
                            "lat": 46.476370931796296,
                            "lng": 16.17871463298798
                        }, {"lat": 46.47702480589416, "lng": 16.17772221565247}, {
                            "lat": 46.4773535870764,
                            "lng": 16.176611781120304
                        }, {"lat": 46.47737575202845, "lng": 16.175785660743717}, {
                            "lat": 46.47768975454584,
                            "lng": 16.1757105588913
                        }, {"lat": 46.47777841375213, "lng": 16.175281405448917}, {
                            "lat": 46.47829928367172,
                            "lng": 16.17473959922791
                        }, {"lat": 46.47848398811945, "lng": 16.174546480178837}, {
                            "lat": 46.47926343398309,
                            "lng": 16.174272894859318
                        }, {"lat": 46.4793483966014, "lng": 16.174557209014896}, {
                            "lat": 46.47987294548081,
                            "lng": 16.174546480178837
                        }, {"lat": 46.48021279135104, "lng": 16.175013184547428}, {
                            "lat": 46.48070408651966,
                            "lng": 16.175292134284977
                        }, {"lat": 46.48204127345637, "lng": 16.176847815513614}, {
                            "lat": 46.48221857754375,
                            "lng": 16.17675125598908
                        }, {"lat": 46.48262120342957, "lng": 16.17766857147217}, {
                            "lat": 46.48255840876275,
                            "lng": 16.177861690521244
                        }, {"lat": 46.482362636689246, "lng": 16.17797434329987}, {
                            "lat": 46.48295733835481,
                            "lng": 16.17899358272553
                        }, {"lat": 46.483116170618935, "lng": 16.179031133651737}, {
                            "lat": 46.483330408752266,
                            "lng": 16.179326176643375
                        }, {"lat": 46.483574195946716, "lng": 16.17993772029877}, {
                            "lat": 46.48395834324827,
                            "lng": 16.18049561977387
                        }, {"lat": 46.48429446991199, "lng": 16.180506348609928}, {
                            "lat": 46.4844126678057,
                            "lng": 16.180248856544498
                        }, {"lat": 46.485240045870896, "lng": 16.18093013763428}, {
                            "lat": 46.486429379793734,
                            "lng": 16.18097841739655
                        }, {"lat": 46.48710529400629, "lng": 16.181820631027225}, {
                            "lat": 46.487223485792775,
                            "lng": 16.18170261383057
                        }, {"lat": 46.48714592246186, "lng": 16.1809515953064}, {
                            "lat": 46.487965872079016,
                            "lng": 16.178038716316227
                        }, {"lat": 46.489070199101704, "lng": 16.177319884300235}, {
                            "lat": 46.49059553701281,
                            "lng": 16.176863908767704
                        }, {"lat": 46.49043303331482, "lng": 16.175828576087955}, {
                            "lat": 46.49022990300957,
                            "lng": 16.17523849010468
                        }, {"lat": 46.48995659922008, "lng": 16.17443382740021}, {
                            "lat": 46.489701760665554,
                            "lng": 16.17370963096619
                        }, {"lat": 46.48939152081373, "lng": 16.172840595245365}, {
                            "lat": 46.489288107136545,
                            "lng": 16.172556281089786
                        }, {"lat": 46.488878143838754, "lng": 16.171660423278812}, {
                            "lat": 46.48828350689756,
                            "lng": 16.170807480812076
                        }, {"lat": 46.488139463430635, "lng": 16.170587539672855}, {
                            "lat": 46.48783660156368,
                            "lng": 16.169868707656864
                        }, {"lat": 46.4874340142763, "lng": 16.167878508567814}, {
                            "lat": 46.487696250370625,
                            "lng": 16.166821718215946
                        }, {"lat": 46.48806559483809, "lng": 16.16648375988007}, {
                            "lat": 46.4890295720834,
                            "lng": 16.16649448871613
                        }, {"lat": 46.48921793345793, "lng": 16.1664891242981}, {
                            "lat": 46.489594654250055,
                            "lng": 16.16647839546204
                        }, {"lat": 46.4899861456419, "lng": 16.166108250617985}, {
                            "lat": 46.49034070145195,
                            "lng": 16.165802478790287
                        }, {"lat": 46.49132310443458, "lng": 16.165582537651066}, {
                            "lat": 46.4922279335884,
                            "lng": 16.164777874946598
                        }, {"lat": 46.49356853033342, "lng": 16.164112687110904}, {
                            "lat": 46.493952607057345,
                            "lng": 16.163774728775028
                        }, {"lat": 46.49427389992544, "lng": 16.16323828697205}, {
                            "lat": 46.49427759293587,
                            "lng": 16.162809133529667
                        }, {"lat": 46.495252538919, "lng": 16.16251409053803}, {
                            "lat": 46.49706943706589,
                            "lng": 16.161580681800846
                        }, {"lat": 46.49715067906103, "lng": 16.161543130874637}, {
                            "lat": 46.49760120064938,
                            "lng": 16.16122126579285
                        }, {"lat": 46.498059104012555, "lng": 16.160883307456974}, {
                            "lat": 46.49834713802341,
                            "lng": 16.1606901884079
                        }, {"lat": 46.498620399648956, "lng": 16.16048634052277}, {
                            "lat": 46.49980944100359,
                            "lng": 16.159799695014957
                        }, {"lat": 46.500045769106855, "lng": 16.159654855728153}, {
                            "lat": 46.50035964078091,
                            "lng": 16.15947246551514
                        }, {"lat": 46.500655047760155, "lng": 16.159311532974247}, {
                            "lat": 46.500477803765214,
                            "lng": 16.159118413925174
                        }, {"lat": 46.50009746574253, "lng": 16.15871608257294}, {
                            "lat": 46.49988698627572,
                            "lng": 16.15849614143372
                        }, {"lat": 46.49966173541744, "lng": 16.15826010704041}, {
                            "lat": 46.499336781715485,
                            "lng": 16.157911419868473
                        }, {"lat": 46.49904136757389, "lng": 16.157605648040775}, {
                            "lat": 46.498601936068866,
                            "lng": 16.157138943672184
                        }, {"lat": 46.498454227202465, "lng": 16.15698873996735}, {
                            "lat": 46.49767136351161,
                            "lng": 16.156350374221805
                        }, {"lat": 46.497239306553645, "lng": 16.155636906623844}, {
                            "lat": 46.49706205142394,
                            "lng": 16.155390143394474
                        }, {"lat": 46.496932802527596, "lng": 16.155170202255253}, {
                            "lat": 46.49678508912702,
                            "lng": 16.154800057411197
                        }, {"lat": 46.49640472527368, "lng": 16.153936386108402}, {
                            "lat": 46.495747391177574,
                            "lng": 16.15325510501862
                        }, {"lat": 46.49524884597477, "lng": 16.15249872207642}, {
                            "lat": 46.495141750483,
                            "lng": 16.152353882789615
                        }, {"lat": 46.49499033925544, "lng": 16.152192950248722}, {
                            "lat": 46.49482046274219,
                            "lng": 16.152053475379947
                        }, {"lat": 46.49385289509162, "lng": 16.15196764469147}, {
                            "lat": 46.49377534121413,
                            "lng": 16.151817440986637
                        }, {"lat": 46.493823350770384, "lng": 16.15120589733124}, {
                            "lat": 46.493952607057345,
                            "lng": 16.151050329208378
                        }, {"lat": 46.494669050620075, "lng": 16.15100204944611}, {
                            "lat": 46.4950198829427,
                            "lng": 16.150835752487186
                        }, {"lat": 46.49573631244494, "lng": 16.150095462799076}, {
                            "lat": 46.49581017061984,
                            "lng": 16.149789690971378
                        }, {"lat": 46.49570307623351, "lng": 16.149135231971744}, {
                            "lat": 46.49540025648259,
                            "lng": 16.147890686988834
                        }, {"lat": 46.49549257975608, "lng": 16.147397160530094}, {
                            "lat": 46.49594311508186,
                            "lng": 16.14646375179291
                        }, {"lat": 46.495909878996805, "lng": 16.145144104957584}, {
                            "lat": 46.495732619533555,
                            "lng": 16.144334077835087
                        }, {"lat": 46.495189758832986, "lng": 16.144194602966312}, {
                            "lat": 46.49501249702239,
                            "lng": 16.14399611949921
                        }, {"lat": 46.49439576913747, "lng": 16.143942475318912}, {
                            "lat": 46.49367932197445,
                            "lng": 16.14398002624512
                        }, {"lat": 46.49278559630358, "lng": 16.14428579807282}, {
                            "lat": 46.492224240438766,
                            "lng": 16.144167780876163
                        }, {"lat": 46.49126031981233, "lng": 16.14426970481873}, {
                            "lat": 46.4901338775103,
                            "lng": 16.144146323204044
                        }, {"lat": 46.48990858625037, "lng": 16.143953204154972}, {
                            "lat": 46.48933242730799,
                            "lng": 16.143958568573
                        }, {"lat": 46.4875632857484, "lng": 16.1427515745163}, {
                            "lat": 46.48723456625958,
                            "lng": 16.14274084568024
                        }, {"lat": 46.486104347018056, "lng": 16.141598224639896}, {
                            "lat": 46.486119121277255,
                            "lng": 16.141453385353092
                        }, {"lat": 46.48547274368446, "lng": 16.140064001083378}, {
                            "lat": 46.484375730991495,
                            "lng": 16.138594150543216
                        }, {"lat": 46.4844385235607, "lng": 16.138417124748234}, {
                            "lat": 46.48437203730869,
                            "lng": 16.137328147888187
                        }, {"lat": 46.48467491846531, "lng": 16.135938763618473}, {
                            "lat": 46.48485221396964,
                            "lng": 16.134694218635563
                        }, {"lat": 46.48495932972347, "lng": 16.13399684429169}, {
                            "lat": 46.4854025650836,
                            "lng": 16.133347749710087
                        }, {"lat": 46.48573129564324, "lng": 16.132564544677738}, {
                            "lat": 46.48576453794879,
                            "lng": 16.13249480724335
                        }, {"lat": 46.486270557202815, "lng": 16.130574345588688}, {
                            "lat": 46.4863407346839,
                            "lng": 16.129662394523624
                        }, {"lat": 46.48705727851967, "lng": 16.12802624702454}, {
                            "lat": 46.48735275776519,
                            "lng": 16.12650275230408
                        }, {"lat": 46.48738599907988, "lng": 16.125719547271732}, {
                            "lat": 46.48789569669453,
                            "lng": 16.125129461288456
                        }, {"lat": 46.488446017017694, "lng": 16.124861240386966}, {
                            "lat": 46.48872671517254,
                            "lng": 16.124721765518192
                        }, {"lat": 46.48897417155499, "lng": 16.124609112739567}, {
                            "lat": 46.4895392542972,
                            "lng": 16.123922467231754
                        }, {"lat": 46.48974977381784, "lng": 16.12348794937134}, {
                            "lat": 46.489742387181806,
                            "lng": 16.122860312461857
                        }, {"lat": 46.489291800485546, "lng": 16.121599674224857}, {
                            "lat": 46.48957249427568,
                            "lng": 16.121127605438236
                        }, {"lat": 46.490192970145316, "lng": 16.12055361270905}, {
                            "lat": 46.49041087368204,
                            "lng": 16.12000107765198
                        }, {"lat": 46.48963158752061, "lng": 16.119201779365543}, {
                            "lat": 46.48931396057429,
                            "lng": 16.119126677513126
                        }, {"lat": 46.48931396057429, "lng": 16.118933558464054}, {
                            "lat": 46.49028899554026,
                            "lng": 16.11782312393189
                        }, {"lat": 46.50102061167493, "lng": 16.108971834182743}, {
                            "lat": 46.50086921681649,
                            "lng": 16.108440756797794
                        }, {"lat": 46.50093199034554, "lng": 16.107620000839237}, {
                            "lat": 46.500824906046454,
                            "lng": 16.107464432716373
                        }, {"lat": 46.500570118418025, "lng": 16.104905605316166}, {
                            "lat": 46.50029317398939,
                            "lng": 16.10230386257172
                        }, {"lat": 46.49993499043636, "lng": 16.101171970367435}, {
                            "lat": 46.49904506026054,
                            "lng": 16.099246144294742
                        }, {"lat": 46.49890104529437, "lng": 16.09776556491852}, {
                            "lat": 46.49928139168619,
                            "lng": 16.093903183937076
                        }, {"lat": 46.49969127656677, "lng": 16.09252989292145}, {
                            "lat": 46.50024517014501,
                            "lng": 16.08954727649689
                        }, {"lat": 46.50083967630716, "lng": 16.087659001350406}, {
                            "lat": 46.500943068019595,
                            "lng": 16.086618304252628
                        }, {"lat": 46.50093937546184, "lng": 16.085985302925113}, {
                            "lat": 46.50047041858622,
                            "lng": 16.08309388160706
                        }, {"lat": 46.5003965667411, "lng": 16.082004904747013}, {
                            "lat": 46.50009746574253,
                            "lng": 16.080712080001835
                        }, {"lat": 46.500045769106855, "lng": 16.080454587936405}, {
                            "lat": 46.49948448818441,
                            "lng": 16.078780889511112
                        }, {"lat": 46.49944017628584, "lng": 16.077756285667423}, {
                            "lat": 46.49997930193164,
                            "lng": 16.074939966201786
                        }, {"lat": 46.50030794439454, "lng": 16.074221134185795}, {
                            "lat": 46.500769517533165,
                            "lng": 16.073196530342106
                        }, {"lat": 46.50153018151568, "lng": 16.07156574726105}, {
                            "lat": 46.50162987940417,
                            "lng": 16.071211695671085
                        }, {"lat": 46.50162987940417, "lng": 16.07033729553223}, {
                            "lat": 46.50097999358354,
                            "lng": 16.069736480712894
                        }, {"lat": 46.50014177710539, "lng": 16.069210767745975}, {
                            "lat": 46.49975035881729,
                            "lng": 16.068754792213443
                        }, {"lat": 46.499654350127585, "lng": 16.068175435066227}, {
                            "lat": 46.49960265307063,
                            "lng": 16.067794561386112
                        }, {"lat": 46.4998574452327, "lng": 16.066480278968815}, {
                            "lat": 46.49978728519126,
                            "lng": 16.065798997879032
                        }, {"lat": 46.499333089048605, "lng": 16.0645866394043}, {
                            "lat": 46.49898966993408,
                            "lng": 16.064028739929203
                        }, {"lat": 46.499137377345946, "lng": 16.063240170478824}, {
                            "lat": 46.49947341021314,
                            "lng": 16.062773466110233
                        }, {"lat": 46.49993499043636, "lng": 16.061534285545353}, {
                            "lat": 46.50104276698471,
                            "lng": 16.060472130775455
                        }, {"lat": 46.50167788202603, "lng": 16.058739423751835}, {
                            "lat": 46.50247176539451,
                            "lng": 16.05709254741669
                        }, {"lat": 46.502937012585726, "lng": 16.055499315261844}, {
                            "lat": 46.503276714369235,
                            "lng": 16.054748296737674
                        }, {"lat": 46.503513027401524, "lng": 16.053160429000858}, {
                            "lat": 46.50398565038449,
                            "lng": 16.051411628723148
                        }, {"lat": 46.50473888477119, "lng": 16.050794720649723}, {
                            "lat": 46.50541457144371,
                            "lng": 16.04982376098633
                        }, {"lat": 46.505769026667544, "lng": 16.04958236217499}, {
                            "lat": 46.50631547394292,
                            "lng": 16.047806739807132
                        }, {"lat": 46.50650665996319, "lng": 16.046551465988163}, {
                            "lat": 46.50693206663724,
                            "lng": 16.04510843753815
                        }, {"lat": 46.50760772604953, "lng": 16.043761968612674}, {
                            "lat": 46.508892563400785,
                            "lng": 16.042721271514896
                        }, {"lat": 46.5092100759972, "lng": 16.042061448097233}, {
                            "lat": 46.50932821974566,
                            "lng": 16.040757894515995
                        }, {"lat": 46.50926545591127, "lng": 16.040033698081974}, {
                            "lat": 46.50906608795656,
                            "lng": 16.03911638259888
                        }, {"lat": 46.50832768175457, "lng": 16.03738903999329}, {
                            "lat": 46.50810246586664,
                            "lng": 16.036584377288822
                        }, {"lat": 46.507969551134266, "lng": 16.035007238388065}, {
                            "lat": 46.50823907233624,
                            "lng": 16.03361248970032
                        }, {"lat": 46.50885933523115, "lng": 16.03191733360291}, {
                            "lat": 46.50907716397324,
                            "lng": 16.031010746955875
                        }, {"lat": 46.50821322789533, "lng": 16.028789877891544}, {
                            "lat": 46.50734558596317,
                            "lng": 16.02616667747498
                        }, {"lat": 46.50725697494387, "lng": 16.024256944656376}, {
                            "lat": 46.50731974109745,
                            "lng": 16.02393507957459
                        }, {"lat": 46.50739727565773, "lng": 16.023495197296146}, {
                            "lat": 46.507803407261754,
                            "lng": 16.022486686706547
                        }, {"lat": 46.509191616013304, "lng": 16.0211455821991}, {
                            "lat": 46.509446363237316,
                            "lng": 16.02037310600281
                        }, {"lat": 46.509590350270585, "lng": 16.019493341445926}, {
                            "lat": 46.50960881011907,
                            "lng": 16.016221046447757
                        }, {"lat": 46.51021428967371, "lng": 16.01266980171204}, {
                            "lat": 46.510280744335994,
                            "lng": 16.012106537818912
                        }, {"lat": 46.510203213888765, "lng": 16.01162374019623}, {
                            "lat": 46.51186824822491,
                            "lng": 16.01313650608063
                        }, {"lat": 46.512898255014704, "lng": 16.014311313629154}, {
                            "lat": 46.51275796849988,
                            "lng": 16.012482047080997
                        }, {"lat": 46.51290563850545, "lng": 16.011580824851993}, {
                            "lat": 46.513359721258695,
                            "lng": 16.01092636585236
                        }, {"lat": 46.51506895809021, "lng": 16.008507013320926}, {
                            "lat": 46.51535690198453,
                            "lng": 16.008088588714603
                        }, {"lat": 46.51639422567282, "lng": 16.005980372428898}, {
                            "lat": 46.51726541461047,
                            "lng": 16.0046660900116
                        }, {"lat": 46.51759395257453, "lng": 16.002718806266788}, {
                            "lat": 46.51796309398454,
                            "lng": 16.002579331398014
                        }, {"lat": 46.518254713925394, "lng": 16.003110408782963}, {
                            "lat": 46.51892284991443,
                            "lng": 16.002370119094852
                        }, {"lat": 46.519096342446396, "lng": 16.002284288406376}, {
                            "lat": 46.51959466897872,
                            "lng": 16.002075076103214
                        }, {"lat": 46.52186107421039, "lng": 16.002219915390018}, {
                            "lat": 46.5222043488432,
                            "lng": 16.002230644226078
                        }, {"lat": 46.52254393021689, "lng": 16.00220382213593}, {
                            "lat": 46.523780431413485,
                            "lng": 16.001999974250797
                        }, {"lat": 46.52482128523129, "lng": 16.001613736152652}, {
                            "lat": 46.52521252299884,
                            "lng": 16.00120604038239
                        }, {"lat": 46.52606880714848, "lng": 16.00069105625153}, {
                            "lat": 46.526201677617145,
                            "lng": 16.000589132308964
                        }, {"lat": 46.52698043966273, "lng": 15.99988102912903}, {
                            "lat": 46.52983703517622,
                            "lng": 15.998786687850954
                        }, {"lat": 46.52985917874069, "lng": 15.998008847236635}, {
                            "lat": 46.530062160994305,
                            "lng": 15.997059345245363
                        }, {"lat": 46.53043490824815, "lng": 15.996308326721193}, {
                            "lat": 46.5312763480891,
                            "lng": 15.995809435844423
                        }, {"lat": 46.53167122983586, "lng": 15.994505882263185}, {
                            "lat": 46.53173027732694,
                            "lng": 15.99439859390259
                        }, {"lat": 46.53187789577378, "lng": 15.994221568107607}, {
                            "lat": 46.533261799200034,
                            "lng": 15.99113702774048
                        }, {"lat": 46.53454233975764, "lng": 15.989366769790651}, {
                            "lat": 46.53518075359766,
                            "lng": 15.98891615867615
                        }, {"lat": 46.538391160291184, "lng": 15.988830327987673}, {
                            "lat": 46.53881551019166,
                            "lng": 15.988771319389345
                        }, {"lat": 46.53983762633742, "lng": 15.98862648010254}, {
                            "lat": 46.54030255366885,
                            "lng": 15.9883850812912
                        }, {"lat": 46.540402180436196, "lng": 15.988229513168337}, {
                            "lat": 46.540505496890745,
                            "lng": 15.988100767135622
                        }, {"lat": 46.54158661799749, "lng": 15.986593365669252}, {
                            "lat": 46.542283985036846,
                            "lng": 15.98540782928467
                        }, {"lat": 46.54353479553816, "lng": 15.983927249908449}, {
                            "lat": 46.544010760106346,
                            "lng": 15.982838273048403
                        }, {"lat": 46.54456420203142, "lng": 15.982816815376284}, {
                            "lat": 46.54554562517112,
                            "lng": 15.982446670532228
                        }, {"lat": 46.5463757624717, "lng": 15.981186032295229}, {
                            "lat": 46.5473497740516,
                            "lng": 15.979737639427187
                        }, {"lat": 46.549408422836656, "lng": 15.977629423141481}, {
                            "lat": 46.55038606919814,
                            "lng": 15.976277589797975
                        }, {"lat": 46.5511829301871, "lng": 15.976964235305788}, {
                            "lat": 46.55165513858579,
                            "lng": 15.977693796157839
                        }, {"lat": 46.552558963516134, "lng": 15.976272225379946}, {
                            "lat": 46.5524446028261,
                            "lng": 15.975880622863771
                        }, {"lat": 46.55181745927397, "lng": 15.975456833839418}, {
                            "lat": 46.55180639196975,
                            "lng": 15.975258350372316
                        }, {"lat": 46.55313814137466, "lng": 15.97566604614258}, {
                            "lat": 46.553219299561725,
                            "lng": 15.975558757781984
                        }, {"lat": 46.55408620898774, "lng": 15.973364710807802}, {
                            "lat": 46.55424483346903,
                            "lng": 15.971798300743105
                        }, {"lat": 46.55415998787391, "lng": 15.97141206264496}, {
                            "lat": 46.554171054698195,
                            "lng": 15.970752239227297
                        }, {"lat": 46.55469857070441, "lng": 15.969829559326174}, {
                            "lat": 46.5549936823173,
                            "lng": 15.968981981277468
                        }, {"lat": 46.55539945816458, "lng": 15.968241691589357}, {
                            "lat": 46.55578309799345,
                            "lng": 15.967807173728945
                        }, {"lat": 46.55616673511011, "lng": 15.967056155204775}, {
                            "lat": 46.5568380935381,
                            "lng": 15.966267585754396
                        }, {"lat": 46.55759797272659, "lng": 15.966364145278932}, {
                            "lat": 46.55825087013178,
                            "lng": 15.96715807914734
                        }, {"lat": 46.55880416686776, "lng": 15.967023968696596}, {
                            "lat": 46.558973843403066,
                            "lng": 15.966804027557375
                        }, {"lat": 46.559560329949264, "lng": 15.966578722000124}, {
                            "lat": 46.56040131846218,
                            "lng": 15.966627001762392
                        }, {"lat": 46.56264389076904, "lng": 15.965634584426882}, {
                            "lat": 46.564097087141775,
                            "lng": 15.964272022247316
                        }, {"lat": 46.565056027896155, "lng": 15.963837504386904}, {
                            "lat": 46.56538058861969,
                            "lng": 15.963885784149172
                        }, {"lat": 46.56607027370969, "lng": 15.964513421058657}, {
                            "lat": 46.56669725254611,
                            "lng": 15.964846014976503
                        }, {"lat": 46.56788849237063, "lng": 15.966761112213137}, {
                            "lat": 46.56812821239318,
                            "lng": 15.966997146606447
                        }, {"lat": 46.56834949147365, "lng": 15.96742630004883}, {
                            "lat": 46.56850438629289,
                            "lng": 15.968241691589357
                        }, {"lat": 46.56924566251839, "lng": 15.96893906593323}, {
                            "lat": 46.56936736361711,
                            "lng": 15.9691858291626
                        }, {"lat": 46.5700274951222, "lng": 15.969577431678774}, {
                            "lat": 46.57015288232133,
                            "lng": 15.969808101654055
                        }, {"lat": 46.571351421802724, "lng": 15.970274806022646}, {
                            "lat": 46.5720668450543,
                            "lng": 15.97091317176819
                        }, {"lat": 46.572667940799924, "lng": 15.970854163169863}, {
                            "lat": 46.57307727212646,
                            "lng": 15.971304774284365
                        }, {"lat": 46.573309593937424, "lng": 15.972125530242922}, {
                            "lat": 46.573943864290285,
                            "lng": 15.972865819931032
                        }, {"lat": 46.57471825408218, "lng": 15.973294973373415}, {
                            "lat": 46.57527875789013,
                            "lng": 15.973311066627504
                        }, {"lat": 46.576635743117954, "lng": 15.973718762397768}, {
                            "lat": 46.5772957861569,
                            "lng": 15.973262786865236
                        }, {"lat": 46.5783835490533, "lng": 15.972849726676943}, {
                            "lat": 46.578925578689635,
                            "lng": 15.973117947578432
                        }, {"lat": 46.579688835725136, "lng": 15.97167491912842}, {
                            "lat": 46.579574532215446,
                            "lng": 15.973466634750368
                        }, {"lat": 46.580400461833136, "lng": 15.973348617553713}, {
                            "lat": 46.58077655061515,
                            "lng": 15.973112583160402
                        }, {"lat": 46.581351740176984, "lng": 15.973117947578432}, {
                            "lat": 46.5829039841164,
                            "lng": 15.973573923110964
                        }, {"lat": 46.584116989418646, "lng": 15.974389314651491}, {
                            "lat": 46.58461103243216,
                            "lng": 15.97439467906952
                        }, {"lat": 46.58551430839012, "lng": 15.974056720733644}, {
                            "lat": 46.58547744027829,
                            "lng": 15.973852872848513
                        }, {"lat": 46.585709708964195, "lng": 15.973868966102602}, {
                            "lat": 46.585787131638334,
                            "lng": 15.973606109619142
                        }, {"lat": 46.58557698412269, "lng": 15.973091125488283}, {
                            "lat": 46.58591985596533,
                            "lng": 15.972930192947388
                        }, {"lat": 46.58636595479377, "lng": 15.972447395324709}, {
                            "lat": 46.58690053119895,
                            "lng": 15.97093999385834
                        }, {"lat": 46.58684523043599, "lng": 15.970371365547182}, {
                            "lat": 46.58702956609312,
                            "lng": 15.969378948211672
                        }, {"lat": 46.587247081362285, "lng": 15.968601107597353}, {
                            "lat": 46.58771160257401,
                            "lng": 15.967742800712587
                        }, {"lat": 46.58811344707757, "lng": 15.965999364852907}, {
                            "lat": 46.58790330858032,
                            "lng": 15.962512493133547
                        }, {"lat": 46.587995474688185, "lng": 15.961648821830751}, {
                            "lat": 46.58816137328741,
                            "lng": 15.96142888069153
                        }, {"lat": 46.58813925350351, "lng": 15.960849523544313}, {
                            "lat": 46.58855952785392,
                            "lng": 15.959798097610475
                        }, {"lat": 46.58827934531571, "lng": 15.959208011627199}, {
                            "lat": 46.5881540000271,
                            "lng": 15.958355069160463
                        }, {"lat": 46.58827565869372, "lng": 15.957217812538149}, {
                            "lat": 46.5880618341888,
                            "lng": 15.956622362136843
                        }, {"lat": 46.58721021442894, "lng": 15.955592393875124}, {
                            "lat": 46.58677518272192,
                            "lng": 15.953827500343325
                        }, {"lat": 46.58675674909793, "lng": 15.952990651130678}, {
                            "lat": 46.587213901123405,
                            "lng": 15.95095753669739
                        }, {"lat": 46.58720284103925, "lng": 15.95044791698456}, {
                            "lat": 46.587545702598234,
                            "lng": 15.948602557182314
                        }, {"lat": 46.58822404595943, "lng": 15.946118831634523}, {
                            "lat": 46.58842680999004,
                            "lng": 15.946016907691957
                        }, {"lat": 46.588765977218486, "lng": 15.94518542289734}, {
                            "lat": 46.5888692016059,
                            "lng": 15.944407582283022
                        }, {"lat": 46.58940006677884, "lng": 15.944289565086367}, {
                            "lat": 46.589779779650506,
                            "lng": 15.943779945373537
                        }, {"lat": 46.590937334513505, "lng": 15.940105319023134}, {
                            "lat": 46.591372332816405,
                            "lng": 15.940110683441164
                        }, {"lat": 46.59195109633199, "lng": 15.939606428146364}, {
                            "lat": 46.5928026416056,
                            "lng": 15.939263105392458
                        }, {"lat": 46.59299064330448, "lng": 15.938737392425539}, {
                            "lat": 46.59308648705685,
                            "lng": 15.937401652336122
                        }, {"lat": 46.592972211794205, "lng": 15.937348008155825}, {
                            "lat": 46.59290217199801,
                            "lng": 15.936462879180908
                        }, {"lat": 46.59312334999341, "lng": 15.93571186065674}, {
                            "lat": 46.59416287448068,
                            "lng": 15.933651924133303
                        }, {"lat": 46.59408914999668, "lng": 15.933077931404116}, {
                            "lat": 46.59507336359023,
                            "lng": 15.932257175445558
                        }, {"lat": 46.59530190501347, "lng": 15.931742191314699}, {
                            "lat": 46.59575161274174,
                            "lng": 15.930030941963198
                        }, {"lat": 46.59570737936018, "lng": 15.929848551750185}, {
                            "lat": 46.595950662511925,
                            "lng": 15.929349660873415
                        }, {"lat": 46.595574678998865, "lng": 15.924934744834902}, {
                            "lat": 46.59606124540165,
                            "lng": 15.923674106597902
                        }, {"lat": 46.59611285067299, "lng": 15.923180580139162}, {
                            "lat": 46.597148631797644,
                            "lng": 15.924001336097719
                        }, {"lat": 46.59722972414608, "lng": 15.923073291778566}, {
                            "lat": 46.59760569617589,
                            "lng": 15.922161340713503
                        }, {"lat": 46.59837237615293, "lng": 15.920782685279848}, {
                            "lat": 46.59815490553079,
                            "lng": 15.920273065567018
                        }, {"lat": 46.59903952634439, "lng": 15.919784903526308}, {
                            "lat": 46.59946340203279,
                            "lng": 15.91998875141144
                        }, {"lat": 46.60043646063451, "lng": 15.918636918067934}, {
                            "lat": 46.600676036775944,
                            "lng": 15.918524265289308
                        }, {"lat": 46.60116624311772, "lng": 15.917687416076662}, {
                            "lat": 46.60192181563451,
                            "lng": 15.916705727577211
                        }, {"lat": 46.60232724044591, "lng": 15.916351675987245}, {
                            "lat": 46.602935371974446,
                            "lng": 15.915836691856386
                        }, {"lat": 46.60410001693755, "lng": 15.914377570152284}, {
                            "lat": 46.60523883859197,
                            "lng": 15.913449525833132
                        }, {"lat": 46.6058801062691, "lng": 15.913288593292238}, {
                            "lat": 46.60625601827466,
                            "lng": 15.912569761276247
                        }, {"lat": 46.60694149815745, "lng": 15.912167429924013}, {
                            "lat": 46.60762328406001,
                            "lng": 15.91150224208832
                        }, {"lat": 46.60844141581587, "lng": 15.911459326744081}, {
                            "lat": 46.609355350191734,
                            "lng": 15.911673903465273
                        }, {"lat": 46.610409303753165, "lng": 15.911706089973451}, {
                            "lat": 46.61129740953826,
                            "lng": 15.91072976589203
                        }, {"lat": 46.61167328394929, "lng": 15.910568833351137}, {
                            "lat": 46.612373435210635,
                            "lng": 15.91073513031006
                        }, {"lat": 46.61349734330857, "lng": 15.911657810211183}, {
                            "lat": 46.61501550344385,
                            "lng": 15.912156701087953
                        }, {"lat": 46.61550558003156, "lng": 15.912092328071596}, {
                            "lat": 46.615785621804726,
                            "lng": 15.911829471588137
                        }, {"lat": 46.61620936647099, "lng": 15.910826325416567}, {
                            "lat": 46.61650414341362,
                            "lng": 15.909715890884401
                        }, {"lat": 46.61679154938768, "lng": 15.909217000007631}, {
                            "lat": 46.61779008905621,
                            "lng": 15.90849816799164
                        }, {"lat": 46.618523322327476, "lng": 15.908589363098146}, {
                            "lat": 46.618961783710496,
                            "lng": 15.908369421958925
                        }, {"lat": 46.62021451095507, "lng": 15.908085107803347}, {
                            "lat": 46.62055347922708,
                            "lng": 15.906856656074526
                        }, {"lat": 46.621102455948574, "lng": 15.906175374984743}, {
                            "lat": 46.621386153146595,
                            "lng": 15.906084179878237
                        }, {"lat": 46.621636689695784, "lng": 15.905789136886598}, {
                            "lat": 46.622642508583205,
                            "lng": 15.906143188476564
                        }, {"lat": 46.62313988464459, "lng": 15.906325578689577}, {
                            "lat": 46.623419886935494,
                            "lng": 15.906642079353334
                        }, {"lat": 46.62375146672289, "lng": 15.907639861106874}, {
                            "lat": 46.624860402146794,
                            "lng": 15.908707380294802
                        }, {"lat": 46.62504460848886, "lng": 15.909538865089418}, {
                            "lat": 46.62509618615234,
                            "lng": 15.91048300266266
                        }, {"lat": 46.62430041101774, "lng": 15.912419557571413}, {
                            "lat": 46.624060939358834,
                            "lng": 15.914087891578676
                        }, {"lat": 46.62412725469351, "lng": 15.91523051261902}, {
                            "lat": 46.62404988679516,
                            "lng": 15.916115641593935
                        }, {"lat": 46.62444040934309, "lng": 15.917108058929445}, {
                            "lat": 46.62444040934309,
                            "lng": 15.917623043060305
                        }, {"lat": 46.62465777444653, "lng": 15.918567180633547}, {
                            "lat": 46.62498197840289,
                            "lng": 15.9189909696579
                        }, {"lat": 46.62605036406242, "lng": 15.921313762664797}, {
                            "lat": 46.62722556393389,
                            "lng": 15.922440290451052
                        }, {"lat": 46.62815391695381, "lng": 15.921753644943239}, {
                            "lat": 46.6294543211972,
                            "lng": 15.921120643615724
                        }, {"lat": 46.62963482809874, "lng": 15.92149078845978}, {
                            "lat": 46.630121088593505,
                            "lng": 15.921866297721865
                        }, {"lat": 46.630202131584596, "lng": 15.922177433967592}, {
                            "lat": 46.630275806925724,
                            "lng": 15.923143029212953
                        }, {"lat": 46.63052261858779, "lng": 15.924510955810549}, {
                            "lat": 46.63052261858779,
                            "lng": 15.926077365875246
                        }, {"lat": 46.630780480316105, "lng": 15.92671573162079}, {
                            "lat": 46.6305889260067,
                            "lng": 15.928046107292177
                        }, {"lat": 46.6306515496055, "lng": 15.928544998168947}, {
                            "lat": 46.63201451586485,
                            "lng": 15.928448438644411
                        }, {"lat": 46.632942786748856, "lng": 15.928813219070436}, {
                            "lat": 46.63300909120275,
                            "lng": 15.929033160209658
                        }, {"lat": 46.63360214409434, "lng": 15.929542779922487}, {
                            "lat": 46.63483243185184,
                            "lng": 15.931296944618227
                        }, {"lat": 46.63523761032012, "lng": 15.93218743801117}, {
                            "lat": 46.63550649981075,
                            "lng": 15.933115482330324
                        }, {"lat": 46.63544388182952, "lng": 15.933673381805422}, {
                            "lat": 46.63567593633781,
                            "lng": 15.934059619903566
                        }, {"lat": 46.63570540350577, "lng": 15.934751629829409}, {
                            "lat": 46.63636104384213,
                            "lng": 15.934118628501894
                        }, {"lat": 46.63646049421054, "lng": 15.933904051780702}, {
                            "lat": 46.636692544359335,
                            "lng": 15.933753848075868
                        }, {"lat": 46.63675147757202, "lng": 15.933882594108583}, {
                            "lat": 46.63690617694991,
                            "lng": 15.93370020389557
                        }, {"lat": 46.63748813731497, "lng": 15.934048891067507}, {
                            "lat": 46.63774228259951,
                            "lng": 15.93473017215729
                        }, {"lat": 46.63868150481573, "lng": 15.934928655624391}, {
                            "lat": 46.63911243661179,
                            "lng": 15.9347140789032
                        }, {"lat": 46.63920083245344, "lng": 15.934274196624758}, {
                            "lat": 46.63999638852975,
                            "lng": 15.93396306037903
                        }, {"lat": 46.63970542261784, "lng": 15.932117700576784}, {
                            "lat": 46.639745936952515,
                            "lng": 15.931887030601503
                        }, {"lat": 46.639524949303976, "lng": 15.931758284568788}, {
                            "lat": 46.63950285048947,
                            "lng": 15.931758284568788
                        }, {"lat": 46.639572830037736, "lng": 15.931661725044252}, {
                            "lat": 46.63945496969377,
                            "lng": 15.931634902954103
                        }, {"lat": 46.639469702250814, "lng": 15.931441783905031}, {
                            "lat": 46.63930764390277,
                            "lng": 15.931173563003542
                        }, {"lat": 46.63931132705242, "lng": 15.93095362186432}, {
                            "lat": 46.63899457526496,
                            "lng": 15.930524468421938
                        }, {"lat": 46.63901299111734, "lng": 15.930154323577883}, {
                            "lat": 46.63877726773367,
                            "lng": 15.929440855979921
                        }, {"lat": 46.63888776319763, "lng": 15.929140448570253}, {
                            "lat": 46.63879568365999,
                            "lng": 15.928561091423036
                        }, {"lat": 46.63857100893056, "lng": 15.928148031234743}, {
                            "lat": 46.63837579898201,
                            "lng": 15.928067564964296
                        }, {"lat": 46.63829476823049, "lng": 15.927670598030092}, {
                            "lat": 46.63843841357053,
                            "lng": 15.927091240882875
                        }, {"lat": 46.63837579898201, "lng": 15.926570892333986}, {
                            "lat": 46.63902404062574,
                            "lng": 15.92548191547394
                        }, {"lat": 46.63930396075284, "lng": 15.924773812294008}, {
                            "lat": 46.63959492882366,
                            "lng": 15.924419760704042
                        }, {"lat": 46.63955073124281, "lng": 15.92348098754883}, {
                            "lat": 46.63970542261784,
                            "lng": 15.922638773918154
                        }, {"lat": 46.640217374252806, "lng": 15.92197358608246}, {
                            "lat": 46.64096503592309,
                            "lng": 15.91998875141144
                        }, {"lat": 46.64100923234865, "lng": 15.919125080108644}, {
                            "lat": 46.64073668714921,
                            "lng": 15.917306542396547
                        }, {"lat": 46.639576513169374, "lng": 15.9162175655365}, {
                            "lat": 46.63822478702925,
                            "lng": 15.915874242782595
                        }, {"lat": 46.6382432031436, "lng": 15.91550409793854}, {
                            "lat": 46.63809955728533,
                            "lng": 15.915407538414003
                        }, {"lat": 46.63819532123323, "lng": 15.915144681930544}, {
                            "lat": 46.63816953864855,
                            "lng": 15.914871096611025
                        }, {"lat": 46.6379006623905, "lng": 15.914420485496523}, {
                            "lat": 46.63795222780379,
                            "lng": 15.914077162742617
                        }, {"lat": 46.637845413678846, "lng": 15.913546085357668}, {
                            "lat": 46.63794117807654,
                            "lng": 15.91300427913666
                        }, {"lat": 46.63785646342566, "lng": 15.912285447120668}, {
                            "lat": 46.63773123282933,
                            "lng": 15.912151336669924
                        }, {"lat": 46.63746235439332, "lng": 15.911121368408205}, {
                            "lat": 46.63717137485451,
                            "lng": 15.910622477531435
                        }, {"lat": 46.63763546806011, "lng": 15.909710526466371}, {
                            "lat": 46.63779753141645,
                            "lng": 15.907173156738283
                        }, {"lat": 46.63795222780379, "lng": 15.906615257263185}, {
                            "lat": 46.637904345635924,
                            "lng": 15.90651333332062
                        }, {"lat": 46.637948544561624, "lng": 15.906389951705934}, {
                            "lat": 46.638051675247844,
                            "lng": 15.906427502632143
                        }, {"lat": 46.63807009142113, "lng": 15.90597152709961}, {
                            "lat": 46.63841631431228,
                            "lng": 15.90599298477173
                        }, {"lat": 46.63864098968403, "lng": 15.905794501304628}, {
                            "lat": 46.63879936684452,
                            "lng": 15.905976891517641
                        }, {"lat": 46.63942182142575, "lng": 15.905676484107973}, {
                            "lat": 46.639572830037736,
                            "lng": 15.905773043632509
                        }, {"lat": 46.639657542001416, "lng": 15.905467271804811}, {
                            "lat": 46.639745936952515,
                            "lng": 15.905402898788454
                        }, {"lat": 46.6398785291084, "lng": 15.905526280403139}, {
                            "lat": 46.64009583221681,
                            "lng": 15.905097126960756
                        }, {"lat": 46.640265254373844, "lng": 15.905086398124697}, {
                            "lat": 46.64082508033731,
                            "lng": 15.904549956321718
                        }, {"lat": 46.64196681270384, "lng": 15.901674628257753}, {
                            "lat": 46.64146960963481,
                            "lng": 15.901068449020388
                        }, {"lat": 46.64124494600854, "lng": 15.901030898094179}, {
                            "lat": 46.64084349556663,
                            "lng": 15.898906588554384
                        }, {"lat": 46.64121548185724, "lng": 15.898037552833559}, {
                            "lat": 46.641705321288995,
                            "lng": 15.89725434780121
                        }, {"lat": 46.64173846815803, "lng": 15.89553236961365}, {
                            "lat": 46.64147697563956,
                            "lng": 15.893563628196718
                        }, {"lat": 46.64159851457294, "lng": 15.893107652664186}, {
                            "lat": 46.64213991195928,
                            "lng": 15.891938209533693
                        }, {"lat": 46.64254135278178, "lng": 15.891970396041872}, {
                            "lat": 46.643576246118485,
                            "lng": 15.891605615615847
                        }, {"lat": 46.64441961430408, "lng": 15.891042351722719}, {
                            "lat": 46.64464058196067,
                            "lng": 15.89102625846863
                        }, {"lat": 46.644809989886156, "lng": 15.88901460170746}, {
                            "lat": 46.64518931440418,
                            "lng": 15.887850522994997
                        }, {"lat": 46.64521877639133, "lng": 15.886938571929933}, {
                            "lat": 46.64512302487436,
                            "lng": 15.885699391365053
                        }, {"lat": 46.64623520512277, "lng": 15.885661840438845}, {
                            "lat": 46.6467876040318,
                            "lng": 15.885876417160036
                        }, {"lat": 46.64791448031454, "lng": 15.886960029602053}, {
                            "lat": 46.64922177469131,
                            "lng": 15.887609124183657
                        }, {"lat": 46.650484848823815, "lng": 15.887973904609682}, {
                            "lat": 46.652631638932036,
                            "lng": 15.889127254486086
                        }, {"lat": 46.653809943763875, "lng": 15.890436172485353}, {
                            "lat": 46.654424861403044,
                            "lng": 15.890806317329409
                        }, {"lat": 46.65501399749286, "lng": 15.891487598419191}, {
                            "lat": 46.65510973150124,
                            "lng": 15.891981124877931
                        }, {"lat": 46.65589400757315, "lng": 15.8920294046402}, {
                            "lat": 46.657212154877,
                            "lng": 15.892388820648195
                        }, {"lat": 46.65771657704829, "lng": 15.891879200935366}, {
                            "lat": 46.6587511800628,
                            "lng": 15.89128375053406
                        }, {"lat": 46.661994771508006, "lng": 15.889846086502077}, {
                            "lat": 46.66289307570566,
                            "lng": 15.890125036239626
                        }, {"lat": 46.663622016502316, "lng": 15.890645384788515}, {
                            "lat": 46.664369354690876,
                            "lng": 15.890951156616213
                        }, {"lat": 46.66460128513148, "lng": 15.888928771018984}, {
                            "lat": 46.66500624065827,
                            "lng": 15.88728189468384
                        }, {"lat": 46.66516454063039, "lng": 15.885356068611147}, {
                            "lat": 46.664899479950186,
                            "lng": 15.883092284202577
                        }, {"lat": 46.66465282509427, "lng": 15.883162021636965}, {
                            "lat": 46.664314133010706,
                            "lng": 15.882067680358888
                        }, {"lat": 46.6651130011555, "lng": 15.881488323211672}, {
                            "lat": 46.665013603457936,
                            "lng": 15.880495905876161
                        }, {"lat": 46.66599652821224, "lng": 15.880072116851808}, {
                            "lat": 46.66620268244767,
                            "lng": 15.878210663795473
                        }, {"lat": 46.66656345046788, "lng": 15.87677299976349}, {
                            "lat": 46.667045697836514,
                            "lng": 15.875431895256044
                        }, {"lat": 46.66663707629861, "lng": 15.874396562576296}, {
                            "lat": 46.66611433072876,
                            "lng": 15.8745414018631
                        }, {"lat": 46.66579037319063, "lng": 15.874396562576296}, {
                            "lat": 46.665609986901785,
                            "lng": 15.874139070510866
                        }, {"lat": 46.66537806078876, "lng": 15.874251723289492}, {
                            "lat": 46.66511668254818,
                            "lng": 15.873854756355287
                        }, {"lat": 46.66484794022258, "lng": 15.872958898544313}, {
                            "lat": 46.66425154837169,
                            "lng": 15.872202515602114
                        }, {"lat": 46.66444666494829, "lng": 15.871478319168093}, {
                            "lat": 46.6649068427644,
                            "lng": 15.870888233184816
                        }, {"lat": 46.66398648321494, "lng": 15.870475172996523}, {
                            "lat": 46.662605914511445,
                            "lng": 15.870518088340761
                        }, {"lat": 46.66234084128602, "lng": 15.867835879325868}, {
                            "lat": 46.6646196922667,
                            "lng": 15.867095589637758
                        }, {"lat": 46.6645202936621, "lng": 15.866661071777346}, {
                            "lat": 46.66463073654482,
                            "lng": 15.866129994392397
                        }, {"lat": 46.6668947659165, "lng": 15.864987373352053}, {
                            "lat": 46.667597884196475,
                            "lng": 15.86482107639313
                        }, {"lat": 46.669449507951, "lng": 15.864778161048891}, {
                            "lat": 46.66865438504382,
                            "lng": 15.863764286041262
                        }, {"lat": 46.668728008026605, "lng": 15.862010121345522}, {
                            "lat": 46.66827154391684,
                            "lng": 15.8617901802063
                        }, {"lat": 46.668297312154756, "lng": 15.861677527427675}, {
                            "lat": 46.66803594802943,
                            "lng": 15.861468315124513
                        }, {"lat": 46.66802122325235, "lng": 15.86129665374756}, {
                            "lat": 46.66791078729655,
                            "lng": 15.861387848854067
                        }, {"lat": 46.66782611957761, "lng": 15.861098170280458}, {
                            "lat": 46.66752057844563,
                            "lng": 15.86107671260834
                        }, {"lat": 46.66746167875175, "lng": 15.860717296600344}, {
                            "lat": 46.66681009660612,
                            "lng": 15.860475897789003
                        }, {"lat": 46.66684690936607, "lng": 15.860357880592348}, {
                            "lat": 46.66671806459661,
                            "lng": 15.860148668289186
                        }, {"lat": 46.6669168535409, "lng": 15.859767794609072}, {
                            "lat": 46.667443272584265,
                            "lng": 15.859403014183046
                        }, {"lat": 46.66759052174876, "lng": 15.858909487724306}, {
                            "lat": 46.667943918106985,
                            "lng": 15.858442783355715
                        }, {"lat": 46.668510819938966, "lng": 15.858174562454225}, {
                            "lat": 46.66938324815548,
                            "lng": 15.857005119323732
                        }, {"lat": 46.66925072832075, "lng": 15.85481643676758}, {
                            "lat": 46.668139021356524,
                            "lng": 15.854387283325197
                        }, {"lat": 46.668150064915615, "lng": 15.852203965187075}, {
                            "lat": 46.66840038498346,
                            "lng": 15.852230787277223
                        }, {"lat": 46.6690482668348, "lng": 15.851774811744692}, {
                            "lat": 46.66901145557403,
                            "lng": 15.851678252220156
                        }, {"lat": 46.66914029487697, "lng": 15.851662158966064}, {
                            "lat": 46.669239684986536,
                            "lng": 15.851356387138368
                        }, {"lat": 46.66952681094308, "lng": 15.851399302482607}, {
                            "lat": 46.66951576766528,
                            "lng": 15.85132956504822
                        }, {"lat": 46.66974399494831, "lng": 15.851195454597475}, {
                            "lat": 46.6697182274001,
                            "lng": 15.85108816623688
                        }, {"lat": 46.66980289215523, "lng": 15.85108280181885}, {
                            "lat": 46.66988019464194,
                            "lng": 15.850675106048586
                        }, {"lat": 46.6701378687991, "lng": 15.850771665573122}, {
                            "lat": 46.67018204138841,
                            "lng": 15.850878953933718
                        }, {"lat": 46.670325602054355, "lng": 15.850852131843569}, {
                            "lat": 46.670384498627534,
                            "lng": 15.85057854652405
                        }, {"lat": 46.670697385596284, "lng": 15.850621461868288}, {
                            "lat": 46.67073787671871,
                            "lng": 15.850417613983156
                        }, {"lat": 46.6709550558576, "lng": 15.850380063056948}, {
                            "lat": 46.671017632735655,
                            "lng": 15.850122570991518
                        }, {"lat": 46.67038081759359, "lng": 15.848373770713808}, {
                            "lat": 46.67105076164178,
                            "lng": 15.84758520126343
                        }, {"lat": 46.67044707616618, "lng": 15.846474766731264}, {
                            "lat": 46.66978080571017,
                            "lng": 15.845664739608766
                        }, {"lat": 46.669997988694774, "lng": 15.843476057052614}, {
                            "lat": 46.67038081759359,
                            "lng": 15.843427777290346
                        }, {"lat": 46.671591864233804, "lng": 15.841362476348879}, {
                            "lat": 46.671779592438725,
                            "lng": 15.84060072898865
                        }, {"lat": 46.6719783627685, "lng": 15.840450525283815}, {
                            "lat": 46.67201149108572,
                            "lng": 15.8400696516037
                        }, {"lat": 46.672548903172896, "lng": 15.84060072898865}, {
                            "lat": 46.67262252085087,
                            "lng": 15.840504169464113
                        }, {"lat": 46.672537860512534, "lng": 15.840386152267458}, {
                            "lat": 46.67255626494519,
                            "lng": 15.84009110927582
                        }, {"lat": 46.67285073501532, "lng": 15.840439796447756}, {
                            "lat": 46.6728765010701,
                            "lng": 15.840257406234743
                        }, {"lat": 46.67296852259409, "lng": 15.840278863906862}, {
                            "lat": 46.67311943755424,
                            "lng": 15.840032100677492
                        }, {"lat": 46.67304582055316, "lng": 15.839806795120241}, {
                            "lat": 46.67322250118729,
                            "lng": 15.839731693267824
                        }, {"lat": 46.673303479618234, "lng": 15.839211344718935}, {
                            "lat": 46.673410223728204,
                            "lng": 15.839028954505922
                        }, {"lat": 46.67359794561702, "lng": 15.838996767997743}, {
                            "lat": 46.67363843456637,
                            "lng": 15.839291810989382
                        }, {"lat": 46.673730454792995, "lng": 15.83932399749756}, {
                            "lat": 46.67354641418305,
                            "lng": 15.83959221839905
                        }, {"lat": 46.673653157813135, "lng": 15.839522480964662}, {
                            "lat": 46.67381143246282,
                            "lng": 15.839726328849794
                        }, {"lat": 46.67422736130925, "lng": 15.839409828186037}, {
                            "lat": 46.67407644944297,
                            "lng": 15.839356184005739
                        }, {"lat": 46.67409853413265, "lng": 15.839109420776369}, {
                            "lat": 46.674381953515805,
                            "lng": 15.839002132415773
                        }, {"lat": 46.67445924945324, "lng": 15.839270353317263}, {
                            "lat": 46.67457335277788,
                            "lng": 15.839189887046816
                        }, {"lat": 46.67465432918469, "lng": 15.839291810989382}, {
                            "lat": 46.6749046191295,
                            "lng": 15.839189887046816
                        }, {"lat": 46.67485676967084, "lng": 15.838889479637148}, {
                            "lat": 46.67499295648031,
                            "lng": 15.838690996170046
                        }, {"lat": 46.67506289011396, "lng": 15.838798284530641}, {
                            "lat": 46.6750297636672,
                            "lng": 15.838449597358705
                        }, {"lat": 46.675217479930375, "lng": 15.838444232940676}, {
                            "lat": 46.67550825481538,
                            "lng": 15.838658809661867
                        }, {"lat": 46.6754861707018, "lng": 15.838299393653871}, {
                            "lat": 46.6757990281358,
                            "lng": 15.838154554367067
                        }, {"lat": 46.675891044683446, "lng": 15.838304758071901}, {
                            "lat": 46.67598674172678,
                            "lng": 15.83810091018677
                        }, {"lat": 46.67613028697403, "lng": 15.838127732276918}, {
                            "lat": 46.67621862232128,
                            "lng": 15.8378541469574
                        }, {"lat": 46.67652043366815, "lng": 15.837677121162416}, {
                            "lat": 46.67685904927027,
                            "lng": 15.837720036506655
                        }, {"lat": 46.677079884389336, "lng": 15.837113857269289}, {
                            "lat": 46.67766141255208,
                            "lng": 15.837172865867617
                        }, {"lat": 46.677665093090155, "lng": 15.836969017982485}, {
                            "lat": 46.67787488334526,
                            "lng": 15.837022662162783
                        }, {"lat": 46.6778638417732, "lng": 15.836893916130068}, {
                            "lat": 46.6779779379091,
                            "lng": 15.836867094039919
                        }, {"lat": 46.6781435609035, "lng": 15.836958289146425}, {
                            "lat": 46.67817300493823,
                            "lng": 15.83682954311371
                        }, {"lat": 46.67842695907192, "lng": 15.836684703826906}, {
                            "lat": 46.678272378437036,
                            "lng": 15.836545228958132
                        }, {"lat": 46.678526332103694, "lng": 15.83606779575348}, {
                            "lat": 46.678684592480685,
                            "lng": 15.835949778556826
                        }, {"lat": 46.67882076964333, "lng": 15.836116075515749}, {
                            "lat": 46.678920141950854,
                            "lng": 15.835928320884706
                        }, {"lat": 46.67891646149827, "lng": 15.835762023925783}, {
                            "lat": 46.678997431397,
                            "lng": 15.835740566253662
                        }, {"lat": 46.67950165030861, "lng": 15.834624767303469}, {
                            "lat": 46.678732438549915,
                            "lng": 15.834007859230043
                        }, {"lat": 46.678294461411944, "lng": 15.833165645599367}, {
                            "lat": 46.678084672785864,
                            "lng": 15.833133459091188
                        }, {"lat": 46.67753995465541, "lng": 15.832173228263857}, {
                            "lat": 46.67850424922354,
                            "lng": 15.83207130432129
                        }, {"lat": 46.67856681736055, "lng": 15.831953287124636}, {
                            "lat": 46.678699314352656,
                            "lng": 15.830445885658266
                        }, {"lat": 46.67861098306067, "lng": 15.82784414291382}, {
                            "lat": 46.67905631851986,
                            "lng": 15.825537443161013
                        }, {"lat": 46.67931762935329, "lng": 15.825387239456179}, {
                            "lat": 46.67993961766147,
                            "lng": 15.824641585350038
                        }, {"lat": 46.68110996678189, "lng": 15.824260711669924}, {
                            "lat": 46.68223980807678,
                            "lng": 15.823456048965456
                        }, {"lat": 46.68263727105757, "lng": 15.822892785072328}, {
                            "lat": 46.68296848797454,
                            "lng": 15.822039842605593
                        }, {"lat": 46.683318103628416, "lng": 15.819969177246096}, {
                            "lat": 46.68349843081805,
                            "lng": 15.819534659385683
                        }, {"lat": 46.68437061709623, "lng": 15.818638801574709}, {
                            "lat": 46.68457670124872,
                            "lng": 15.8186012506485
                        }, {"lat": 46.68452150021353, "lng": 15.81885874271393}, {
                            "lat": 46.68459510158124,
                            "lng": 15.81937909126282
                        }, {"lat": 46.68501462746247, "lng": 15.820044279098513}, {
                            "lat": 46.68610758748394,
                            "lng": 15.819990634918215
                        }, {"lat": 46.68653814142092, "lng": 15.820274949073793}, {
                            "lat": 46.68702389046069,
                            "lng": 15.820897221565248
                        }, {"lat": 46.687222604718, "lng": 15.821717977523805}, {
                            "lat": 46.68754275392825,
                            "lng": 15.82233488559723
                        }, {"lat": 46.68800273510411, "lng": 15.82286059856415}, {
                            "lat": 46.68832287969062,
                            "lng": 15.824153423309328
                        }, {"lat": 46.688495830574375, "lng": 15.824233889579775}, {
                            "lat": 46.68853630836082,
                            "lng": 15.825489163398744
                        }, {"lat": 46.688676140480574, "lng": 15.825891494750978}, {
                            "lat": 46.68870557877545,
                            "lng": 15.825789570808412
                        }, {"lat": 46.689338498234605, "lng": 15.825676918029787}, {
                            "lat": 46.68933481849174,
                            "lng": 15.825553536415102
                        }, {"lat": 46.69076621954702, "lng": 15.82436800003052}, {
                            "lat": 46.691193056734946,
                            "lng": 15.823150277137758
                        }, {"lat": 46.691248250952604, "lng": 15.823268294334413}, {
                            "lat": 46.691465347661456,
                            "lng": 15.823300480842592
                        }, {"lat": 46.691811228750844, "lng": 15.82309663295746}, {
                            "lat": 46.692315328073875,
                            "lng": 15.822415351867678
                        }, {"lat": 46.69263176758086, "lng": 15.82162141799927}, {
                            "lat": 46.69273847350819,
                            "lng": 15.821036696434023
                        }, {"lat": 46.69336766624132, "lng": 15.820248126983644}, {
                            "lat": 46.69461499156787,
                            "lng": 15.819910168647768
                        }, {"lat": 46.69499396671287, "lng": 15.819663405418398}, {
                            "lat": 46.695185292960296,
                            "lng": 15.819727778434755
                        }, {"lat": 46.695395015183514, "lng": 15.819534659385683}, {
                            "lat": 46.69536190120243,
                            "lng": 15.81933617591858
                        }, {"lat": 46.695483319033826, "lng": 15.819067955017092}, {
                            "lat": 46.69585860878677,
                            "lng": 15.818665623664858
                        }, {"lat": 46.69600210123834, "lng": 15.818713903427126}, {
                            "lat": 46.696215499563685,
                            "lng": 15.81838130950928
                        }, {"lat": 46.696233895931556, "lng": 15.818150639533998}, {
                            "lat": 46.696583425730424,
                            "lng": 15.818145275115969
                        }, {"lat": 46.69744436316425, "lng": 15.817501544952394}, {
                            "lat": 46.6981066133882,
                            "lng": 15.817383527755739
                        }, {"lat": 46.69889762384429, "lng": 15.816922187805178}, {
                            "lat": 46.699427410414245,
                            "lng": 15.815774202346804
                        }, {"lat": 46.69962607903779, "lng": 15.81485688686371}, {
                            "lat": 46.700222080522515,
                            "lng": 15.814910531044008
                        }, {"lat": 46.70029198150019, "lng": 15.815725922584535}, {
                            "lat": 46.701200685974406,
                            "lng": 15.813660621643068
                        }, {"lat": 46.70113078617321, "lng": 15.812973976135256}, {
                            "lat": 46.70068563268447,
                            "lng": 15.811965465545656
                        }, {"lat": 46.700325092457994, "lng": 15.811589956283571}, {
                            "lat": 46.69985417914859,
                            "lng": 15.810533165931703
                        }, {"lat": 46.69860697483735, "lng": 15.809310078620912}, {
                            "lat": 46.69799255976192,
                            "lng": 15.808441042900087
                        }, {"lat": 46.698297928607424, "lng": 15.80735206604004}, {
                            "lat": 46.697867468410934,
                            "lng": 15.806477665901186
                        }, {"lat": 46.69774973510997, "lng": 15.805978775024416}, {
                            "lat": 46.696822576394496,
                            "lng": 15.80338776111603
                        }, {"lat": 46.69626700937795, "lng": 15.802336335182192}, {
                            "lat": 46.69618238608572,
                            "lng": 15.802170038223268
                        }, {"lat": 46.695976346211, "lng": 15.801547765731813}, {
                            "lat": 46.69632219841011,
                            "lng": 15.79987406730652
                        }, {"lat": 46.69727512013729, "lng": 15.798833370208742}, {
                            "lat": 46.69742596720876,
                            "lng": 15.799010396003725
                        }, {"lat": 46.69780860179255, "lng": 15.79863488674164}, {
                            "lat": 46.698570183712235,
                            "lng": 15.796945095062258
                        }, {"lat": 46.69866216147798, "lng": 15.79661786556244}, {
                            "lat": 46.69857386282586,
                            "lng": 15.796499848365785
                        }, {"lat": 46.69968126463684, "lng": 15.794836878776552}, {
                            "lat": 46.70097259155215,
                            "lng": 15.793914198875429
                        }, {"lat": 46.70156857817333, "lng": 15.793710350990297}, {
                            "lat": 46.70163111959782,
                            "lng": 15.792535543441774
                        }, {"lat": 46.701564899263744, "lng": 15.79187572002411}, {
                            "lat": 46.70278628347032,
                            "lng": 15.791044235229494
                        }, {"lat": 46.70298861854749, "lng": 15.790362954139711}, {
                            "lat": 46.70327924396781,
                            "lng": 15.789842605590822
                        }, {"lat": 46.70369862472803, "lng": 15.789515376091005}, {
                            "lat": 46.70378691499925,
                            "lng": 15.789268612861635
                        }, {"lat": 46.70408121486049, "lng": 15.789193511009218}, {
                            "lat": 46.704500589390754,
                            "lng": 15.788769721984865
                        }, {"lat": 46.70452634035223, "lng": 15.788571238517763}, {
                            "lat": 46.705170110397184,
                            "lng": 15.788281559944155
                        }, {"lat": 46.70531725675809, "lng": 15.788007974624636}, {
                            "lat": 46.706564306062916,
                            "lng": 15.787251591682436
                        }, {"lat": 46.70745451242731, "lng": 15.786441564559938}, {
                            "lat": 46.707469226458734,
                            "lng": 15.786430835723879
                        }, {"lat": 46.70748761899241, "lng": 15.786463022232057}
                    ],
                }
            ],
            // <-- test -->;
            rectangle: {
                bounds: [
                    {"lat": 46.726539721880826, "lng": 15.781860351562502},
                    {"lat": 46.461912465607035, "lng": 16.328086853027347}
                ],
                style: {color: 'blue', weight: 1, fill: false}
            },
            points: [],
            lastCenter: null,
        }
    },
    mounted() {
        this.initGestureHandling();
        this.initContextMenuEvent();
        this.initPopupEvents();
    },
    methods: {
        mouseEvent(e) {
            console.log("mouseEvents", e); // TODO delete
        },
        test(e) {
            console.log("Test", e); // TODO delete
        },
        // <-- lmap &ndash;&gt;
        centerUpdate(e) {
            console.log("centerUpdate", this.lastCenter, e); // TODO delete

        },
        zoomUpdate(e) {
        },
        initGestureHandling() {
            L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
        },
        // <-- context menu &ndash;&gt;
        initContextMenuEvent() {
            let map = this.$refs.dlMap.mapObject;
            // <-- event before context menu is showing &ndash;&gt;
            map.on('contextmenu', function (e) {
                map.contextmenu.removeItem(0);
                // map.contextmenu.removeItem(1);

                map.contextmenu.insertItem({
                    text: e.latlng
                }, 0);
                // map.contextmenu.insertItem({
                //     separator: true
                // }, 1);
                // map.contextmenu.addItem({
                //     text: e.latlng
                // });
                // <-- copy to clipboard &ndash;&gt;
            });
        },
        initPopupEvents() {
            let map = this.$refs.dlMap.mapObject;
            map.on('popupopen', (e) => {
                console.log("popupOpen", this.caller); // TODO delete
            });
        },
        // <--  &ndash;&gt;
        addPoint(e) {
            this.points.push(
                {lat: e.latlng.lat, lng: e.latlng.lng},
            );
        },
        undoPoint(e) {
            this.points.splice(this.points.length - 1, 1);
        },
        showCoordinates(e) {
            alert(e.latlng);
        },
        centerMap(e) {
            let map = this.$refs.dlMap.mapObject;
            map.panTo(e.latlng);
        },
        zoomIn(e) {
            let map = this.$refs.dlMap.mapObject;
            map.zoomIn();
        },
        zoomOut(e) {
            let map = this.$refs.dlMap.mapObject;
            map.zoomOut();
        },
        // <-- polygon &ndash;&gt;
        polygonClick() {
            console.log("polygon click"); // TODO delete
        },
        // <-- layers &ndash;&gt;
        layers() {
            console.clear(); // TODO delete
            // <--  &ndash;&gt;
            let map = this.$refs.dlMap.mapObject;
            // map.eachLayer(layer => {
            //     console.log("layer", layer); // TODO delete
            //     // this.map.remove(layer);
            // });

            // <--  &ndash;&gt;

            let lasPrlekijaPolygon = this.$refs.lasPrlekijaPolygon;

            // layerGroup.eachLayer(function (layer) {
            //     // if (layer.id === 'foo') // it's the marker
            //     //     if (layer.id === 'bar') // it's the polygon
            //
            // });

        },
        circleOnLayers() {
            let map = this.$refs.dlMap.mapObject;

            let oneLayer = new L.geoJSON();
            map.addLayer(oneLayer);
            let redCircleMarker = L.circle([46.620572707322914, 16.018066406250004], 2000, {
                color: 'red',
            });
            oneLayer.addLayer(redCircleMarker);
            oneLayer.bringToFront();
            oneLayer.bringToBack();
        },
        // <-- routing &ndash;&gt;
        routingStart() {
            console.log("routerStart"); // TODO delete
        },
        routesFound() {
            console.log("routerFound"); // TODO delete
        },
        routingMachine() {
            /*           // <-- deprecated &ndash;&gt;
                       console.clear(); // TODO delete
                       let map = this.$refs.lMap.mapObject;

                       let routingControl = L.Routing.control({
                           // geocoder: geocoder,
                           waypoints: [
                               L.latLng(46.51834473343716, 16.196244025819553),
                               L.latLng(46.55580846545135, 16.144662317680986),
                               L.latLng(46.581089, 16.170745),
                               // L.latLng(46.51834473343716, 16.196244025819553),

                               // L.Routing.waypoint(null,"12 Morning Star place, Auckland"),
                               // L.Routing.waypoint(null,"198 Dominion road, Mount Roskill, Auckland")
                           ],
                           router: new L.Routing.osrmv1({
                               language: 'en',
                               profile: 'bike',
                               steps: true,
                           }),
                           useHints: true,
                           // geocoder: L.Control.Geocoder.nominatim(),
                           routeWhileDragging: true,
                           reverseWaypoints: false,
                           showAlternatives: true,
                           altLineOptions: {
                               styles: [
                                   {color: 'black', opacity: 0.15, weight: 29},
                                   {color: 'white', opacity: 0.8, weight: 6},
                                   {color: 'blue', opacity: 0.5, weight: 2}
                               ]
                           },
                           lineOptions: {
                               styles: [
                                   {
                                       color: 'black',
                                       // opacity: 0.15,
                                       weight: 9
                                   },
                                   {
                                       color: 'red',
                                       opacity: 0.8,
                                       weight: 6
                                   },
                                   {
                                       color: 'red',
                                       opacity: 1,
                                       weight: 5
                                   }
                               ],
                               addWaypoints: false,
                           },
                           createMarker: (waypointIndex, waypoint, numberOfWaypoints) => {
                               const marker = L.marker(waypoint.latLng,
                                   {
                                       draggable: false,
                                       bounceOnAdd: true,
                                       // bounceOnAddOptions: {
                                       //     duration: 1000,
                                       //     height: 800,
                                       //     function() {
                                       //         let map = this.$refs.lmap.mapObject;
                                       //         marker.bindPopup("This is the Transamerica Pyramid").openOn(map);
                                       //         // (bindPopup("This is the Transamerica Pyramid").openOn(map))
                                       //     }
                                       // },
                                       icon: this.getMarkerIcons.markerYellow,
                                       // icon: L.icon({
                                       //     iconUrl: require('@/config/leaflet/icons/marker1/marker-purple.png'),
                                       //     shadowUrl: require('@/config/leaflet/icons/marker1/marker-shadow.png'),
                                       //     iconSize: [25, 41],
                                       //     shadowSize: [25, 41],
                                       //     iconAnchor: [12, 41],
                                       //     shadowAnchor: [7, 41],
                                       //     popupAnchor: [0, -41]
                                       // })
                                   });
                               this.caller = waypointIndex;
                               // this.$set(this, 'caller', waypoint);
                               console.log("aaa", waypointIndex, this.caller); // TODO delete
                               // marker.bindPopup("To je pop up goli text.");

                               this.$nextTick(() => {
                                   marker.bindPopup(this.$refs.popupLayer.innerHTML, this.popupOptions);
                               });
                               return marker;
                           }
                       });
                       routingControl.addTo(map);

                       this.routingControl = routingControl;

                       // control.setWaypoints([L.latLng(map.getCenter()), L.latLng(map.getCenter())]);

                       // routingControl.on('routingstart', this.routingStart());
                       // routingControl.on('routesfound', this.routesFound());

                       routingControl.on('routesfound', function (e) {
                           let distance = e.routes[0].summary.totalDistance;
                           // console.log('routing distance: ' + distance);
                           console.log('routing distance: ' + e.routes[0].summary.totalDistance + ' m');
                           console.log('routing time: ' + e.routes[0].summary.totalTime + ' sek');
                       });

                       // routingControl.on('routeselected', function(e) {
                       //     var coord = e.routeManager.coordinates;
                       //     var instr = e.routeManager.instructions;
                       //     // console.log("coord", coord); // TODO delete
                       //     // console.log("instr", instr); // TODO delete
                       //     // L.geoJson(getInstrGeoJson(instr,coord)).addTo(map);
                       // });*/
        },
        routing() {
            console.clear(); // TODO delete
            let map = this.$refs.dlMap.mapObject;
            const waypoints = [
                L.latLng(46.51834473343716, 16.196244025819553),
                L.latLng(46.55580846545135, 16.144662317680986),
                L.latLng(46.581089, 16.170745),
                // L.latLng(46.51834473343716, 16.196244025819553),
                // L.Routing.waypoint(null,"12 Morning Star place, Auckland"),
                // L.Routing.waypoint(null,"198 Dominion road, Mount Roskill, Auckland")
            ];

            // <-- plan &ndash;&gt;

            const plan = new L.Routing.Plan(waypoints, {
                createMarker: (i, wp, nWps) => {
                    let marker = L.marker(wp.latLng, {});
                    // return L.marker(wp.latLng).bindPopup('Message: '+ i);
                    // return L.marker(wp.latLng).bindPopup(this.$refs.popupLayer.innerHTML, this.popupOptions);
                    marker.krneki = i;
                    // marker.bindPopup(this.$refs.popupLayer.innerHTML, this.popupOptions);
                    marker.on('click', (e) => {
                        this.openPopUp(e.latlng, i)
                    });
                    return marker;
                },
            });

            const routingControl = L.Routing.control({
                name: 'test',
                show: false,
                waypoints,
                routeWhileDragging: false,
                plan,
                addWaypoints: false,
                fitSelectedRoutes: false,
            }).addTo(map);

            // console.log("plan", routingControl.getPlan()._markers[1].krneki); // TODO delete
            console.log("plan", routingControl.getWaypoints()); // TODO delete

            // <-- routing &ndash;&gt;
            // let routingControl = L.Routing.control({
            // fitSelectedRoutes: false,
            //     // geocoder: geocoder,
            //     waypoints: this.getWaypoints,
            //     router: new L.Routing.osrmv1({
            //         language: 'en',
            //         profile: 'bike',
            //         steps: true,
            //     }),
            //     createMarker: plan,
            //     // useHints: true,
            //     // geocoder: L.Control.Geocoder.nominatim(),
            //     // routeWhileDragging: true,
            //     // reverseWaypoints: false,
            //     // showAlternatives: true,
            //     // altLineOptions: {
            //     //     styles: [
            //     //         {color: 'black', opacity: 0.15, weight: 29},
            //     //         {color: 'white', opacity: 0.8, weight: 6},
            //     //         {color: 'blue', opacity: 0.5, weight: 2}
            //     //     ]
            //     // },
            //     // lineOptions: {
            //     //     styles: [
            //     //         {
            //     //             color: 'black',
            //     //             // opacity: 0.15,
            //     //             weight: 9
            //     //         },
            //     //         {
            //     //             color: 'red',
            //     //             opacity: 0.8,
            //     //             weight: 6
            //     //         },
            //     //         {
            //     //             color: 'red',
            //     //             opacity: 1,
            //     //             weight: 5
            //     //         }
            //     //     ],
            //     //     addWaypoints: false,
            //     // },
            //     // createMarker: (waypointIndex, waypoint, numberOfWaypoints) => {
            //     //     const marker = L.marker(waypoint.latLng,
            //     //         {
            //     //             draggable: false,
            //     //             bounceOnAdd: true,
            //     //             // bounceOnAddOptions: {
            //     //             //     duration: 1000,
            //     //             //     height: 800,
            //     //             //     function() {
            //     //             //         let map = this.$refs.lmap.mapObject;
            //     //             //         marker.bindPopup("This is the Transamerica Pyramid").openOn(map);
            //     //             //         // (bindPopup("This is the Transamerica Pyramid").openOn(map))
            //     //             //     }
            //     //             // },
            //     //             icon: this.getMarkerIcons.markerYellow,
            //     //             // icon: L.icon({
            //     //             //     iconUrl: require('@/config/leaflet/icons/marker1/marker-purple.png'),
            //     //             //     shadowUrl: require('@/config/leaflet/icons/marker1/marker-shadow.png'),
            //     //             //     iconSize: [25, 41],
            //     //             //     shadowSize: [25, 41],
            //     //             //     iconAnchor: [12, 41],
            //     //             //     shadowAnchor: [7, 41],
            //     //             //     popupAnchor: [0, -41]
            //     //             // })
            //     //         });
            //     //     this.caller = waypointIndex;
            //     //     // this.$set(this, 'caller', waypoint);
            //     //     console.log("aaa", waypointIndex, this.caller); // TODO delete
            //     //     // marker.bindPopup("To je pop up goli text.");
            //     //
            //     //     this.$nextTick(() => {
            //     //         marker.bindPopup(this.$refs.popupLayer.innerHTML, this.popupOptions);
            //     //     });
            //     //     return marker;
            //     // }
            // });
            // routingControl.addTo(map);
            //
            this.routingControl = routingControl;
        },
        openPopUp(latLng, caller) {
            this.caller = caller;
            this.$refs.features.mapObject.openPopup(latLng);
        },
        clearRoute() {
            let routingControl = this.routingControl;
            console.log("clear routeManager", routingControl.getPlan()); // TODO delete
            routingControl.getPlan().setWaypoints({latLng: L.latLng([0, 0])});
        },
        // <-- markers -->
        addMarkers() {
            console.clear(); // TODO delete
            let map = this.$refs.dlMap.mapObject;
            // // <--  &ndash;&gt;
            let marker = L.marker([46.51834473343716, 16.196244025819553]);
            // marker.id = 219;
            // marker.bindPopup("This is the Transamerica Pyramid");
            marker.addTo(map);


            let markerGroup = L.layerGroup().addTo(map);
            // let markerGroup = this.$refs.route1.mapObject;
            marker.addTo(markerGroup);

            marker = L.marker([46.61507526631028, 15.990600585937502]);
            marker.id = 220;
            marker.addTo(markerGroup);
            //
            // // <-- remove layer &ndash;&gt;
            // let id = markerGroup.getLayers()[0]._leaflet_id;
            // console.log("layers", markerGroup.getLayers()[0]._leaflet_id); // TODO delete
            // // markerGroup.removeLayer(id);

            // <-- remove group layers &ndash;&gt;
            // markerGroup.clearLayers();
        },
        // <--  -->
        getView() {
            console.clear(); // TODO delete
            let map = this.$refs.dlMap.mapObject;
            console.log("getView", map.getBounds()); // TODO delete

            let ne = map.getBounds()._northEast;
            let sw = map.getBounds()._southWest;

            let marker = L.marker([46.51834473343716, 16.196244025819553]);
            // marker.id = 219;
            // marker.bindPopup("This is the Transamerica Pyramid");
            marker.addTo(map);

            L.marker([ne.lat, ne.lng]).addTo(map);
            L.marker([sw.lat, sw.lng]).addTo(map);
        },
    },
    computed: {
        getTileProviders() {
            return [
                {
                    name: 'Open street',
                    visible: true,
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    subdomains: ['a', 'b', 'c'],
                    attribution: '&copy; <a target="_blank" href="https://osm.org/copyright">OpenStreetMap</a> contributors',
                },
                {
                    name: 'Base map (en)',
                    visible: false,
                    url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
                    // subdomains: ['a', 'b', 'c'],
                    attribution: '\'&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors\'',
                    minZoom: 3,
                    maxZoom: 20,
                },
                {
                    name: 'Stadia map',
                    visible: false,
                    url: 'https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png',
                    // subdomains: ['a', 'b', 'c'],
                    attribution: '&copy; <a href="https://carto.com/">carto.com</a> contributors',
                    minZoom: 3,
                    maxZoom: 17,
                },
                {
                    name: 'Open street (de)',
                    visible: false,
                    url: 'https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png',
                    subdomains: ['a', 'b', 'c'],
                    attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
                    maxZoom: 17,
                },
                // {
                //     name: 'Open street (fr)',
                //     visible: false,
                //     url: 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
                //     subdomains: ['a', 'b', 'c'],
                //     attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
                // },
                // {
                //     name: 'OpenTopoMap',
                //     visible: false,
                //     url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
                //     attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
                // },
                // <-- google maps &ndash;&gt;
                {
                    name: 'Google streets',
                    visible: false,
                    url: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    // attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
                },
                /*                {
                                    // same as Google streets
                                    name: 'Google traffic',
                                    visible: false,
                                    url: 'https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}',
                                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                                    // attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
                                },*/
                {
                    name: 'Google Maps',
                    visible: false,
                    url: 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    // attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
                },
                {
                    name: 'Google Terrain',
                    visible: false,
                    url: 'https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                    // attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
                },
                // <-- test &ndash;&gt;
                // {
                //     name: 'test',
                //     visible: false,
                //     url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
                //     // subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                //     // attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
                // },
            ];
        },
        getMarkerIcons() {
            let iconSize = [25, 41]; // size of the icon
            let shadowSize = [25, 41]; // size of the shadow
            let iconAnchor = [12, 41]; // point of the icon which will correspond to marker's location
            let shadowAnchor = [7, 41];  // the same for the shadow
            let popupAnchor = [0, -41];  // point from which the popup should open relative to the iconAnchor

            let iconMarkerBlue = L.icon({
                iconUrl: require('@/config/leaflet/icons/marker1/marker-blue.png'),
                shadowUrl: require('@/config/leaflet/icons/marker1/marker-shadow.png'),
                iconSize: iconSize,
                shadowSize: shadowSize,
                iconAnchor: iconAnchor,
                shadowAnchor: shadowAnchor,
                popupAnchor: popupAnchor
            });
            let iconMarkerBlack = L.icon({
                iconUrl: require('@/config/leaflet/icons/marker1/marker-black.png'),
                shadowUrl: require('@/config/leaflet/icons/marker1/marker-shadow.png'),
                iconSize: iconSize,
                shadowSize: shadowSize,
                iconAnchor: iconAnchor,
                shadowAnchor: shadowAnchor,
                popupAnchor: popupAnchor
            });
            let iconMarkerGreen = L.icon({
                iconUrl: require('@/config/leaflet/icons/marker1/marker-green.png'),
                shadowUrl: require('@/config/leaflet/icons/marker1/marker-shadow.png'),
                iconSize: iconSize,
                shadowSize: shadowSize,
                iconAnchor: iconAnchor,
                shadowAnchor: shadowAnchor,
                popupAnchor: popupAnchor
            });
            let iconMarkerPurple = L.icon({
                iconUrl: require('@/config/leaflet/icons/marker1/marker-purple.png'),
                shadowUrl: require('@/config/leaflet/icons/marker1/marker-shadow.png'),
                iconSize: iconSize,
                shadowSize: shadowSize,
                iconAnchor: iconAnchor,
                shadowAnchor: shadowAnchor,
                popupAnchor: popupAnchor
            });
            let iconMarkerRed = L.icon({
                iconUrl: require('@/config/leaflet/icons/marker1/marker-red.png'),
                shadowUrl: require('@/config/leaflet/icons/marker1/marker-shadow.png'),
                iconSize: iconSize,
                shadowSize: shadowSize,
                iconAnchor: iconAnchor,
                shadowAnchor: shadowAnchor,
                popupAnchor: popupAnchor
            });
            let iconMarkerYellow = L.icon({
                iconUrl: require('@/config/leaflet/icons/marker1/marker-yellow.png'),
                shadowUrl: require('@/config/leaflet/icons/marker1/marker-shadow.png'),
                iconSize: iconSize,
                shadowSize: shadowSize,
                iconAnchor: iconAnchor,
                shadowAnchor: shadowAnchor,
                popupAnchor: popupAnchor
            });

            // <--  -->
            return {
                markerBlue: iconMarkerBlue,
                markerBlack: iconMarkerBlack,
                markerGreen: iconMarkerGreen,
                markerPurple: iconMarkerPurple,
                markerRed: iconMarkerRed,
                markerYellow: iconMarkerYellow,
            }
        },
        getWaypoints() {
            let waypoints = [
                L.latLng(46.51834473343716, 16.196244025819553),
                L.latLng(46.55580846545135, 16.144662317680986),
                L.latLng(46.581089, 16.170745),
                // L.latLng(46.51834473343716, 16.196244025819553),

                // L.Routing.waypoint(null,"12 Morning Star place, Auckland"),
                // L.Routing.waypoint(null,"198 Dominion road, Mount Roskill, Auckland")
            ];
            // <--
            return waypoints;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style>

</style>
