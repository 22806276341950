<template>
    <b-card
        title="Card Title"
        img-src="https://picsum.photos/600/300/?image=25"
        img-alt="Image"
        img-top
        tag="article"
        style="max-width: 800px;"
        class="mb-2"
    >
        <b-card-text>
            <p>props: {{testProp}}</p>
            Some quick example text to build on the card title and make up the bulk of the card's content.
        </b-card-text>

        <b-button href="#" variant="primary">Go somewhere</b-button>
    </b-card>
</template>

<script>

// @group DEVELOPER
export default {
    name: "PopupTest",
    components: {},
    props: {
        testProp: null
    },
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
